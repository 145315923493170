/*--------------------------------------------------------------------------
   #sec-inquiry
---------------------------------------------------------------------------*/
.sec-inquiry {
  position: relative;
  z-index: 0;

	@include mq-sp {
	}

	&__contact {
    background: $key-color;
    padding: 85px 0 90px;

    @include mq-sp {
      padding: 45px 0px 60px;
    }

    &-hdg {
      font-size: 31px;
      text-align: center;

      @include mq-sp {
        font-size: 23px;
      }
    }

    &-comment {
      margin-top: 40px;
      text-align: center;

      @include mq-sp {
        margin-top: 20px;
      }
    }

    &-txt {
      font-size: 30px;
      line-height: 1.5;

      @include mq-sp {
        font-size: 23px;
        //line-height: 1.7;
      }

      &:not(:first-of-type) {
        margin-top: 5px;

        @include mq-sp {
          margin-top: 0;
        }
      }

      &:nth-child(3) {
        @include mq-sp {
          margin-top: 15px;
        }
      }

      span {
        padding: 0 10px;
        background: linear-gradient(to bottom, transparent 0, transparent 75%, #fff 75%, #fff 100%);

        @include mq-sp {
        }
      }
    }

    &-btn {
      width: 473px;
      margin: 40px auto 0;

      @include mq-sp {
        width: auto;
        margin-top: 30px;
      }

      &.c-btn-request {
        font-size: 19px;

        a {
          border: 1px solid #fff;
          border-radius: 40px;
          box-shadow: 2px 2px 8px rgba(#000, .2);
        }
      }
    }
  }

  &__icons {
    @include mq-sp {
      display: flex;
      align-items: end;
      justify-content: center;
    }
  }

  &__ico {
    position: absolute;
    top: 90px;

    @include mq-sp {
      position: relative;
      top: auto;
      transform: scale(.8);
    }

    &--left {
      right: calc(50% + 250px);
      @include mq-sp {
        right: auto;
      }
    }
    &--right {
      left: calc(50% + 250px);
      @include mq-sp {
        left: auto;
      }
    }
  }

  &__tel {
    margin-top: 80px;

    @include mq-sp {
      margin-top: 50px;
    }

    &-hdg {
      text-align: center;

      @include mq-sp {
        span.mi-sp {
          display: inline-block;
        }
      }

      span.txt {
        position: relative;
        display: inline-block;
        font-size: 28px;

        @include mq-sp {
          font-size: 20px;
          padding-top: 50px;
        }

        &::before {
          content: '';
          position: absolute;
          top: 5px;
          left: -50px;
          width: 39px;
          height: 39px;
          background: url('../../img/common/ico_tel.svg') no-repeat 50% 50% / cover;

          @include mq-sp {
            top: 0px;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }

    &-list {
      display: flex;
      justify-content: space-between;
      margin-top: 50px;
      padding: 0 30px;

      @include mq-sp {
        flex-direction: column;
        align-items: center;
        margin-top: 20px;
        padding: 0;
      }
    }

    &-item {
      max-width: 416px;
      width: 45%;

      @include mq-sp {
        max-width: none;
        width: 100%;

        &:not(:first-of-type) {
          margin-top: 60px;
        }
      }

      .hdg {
        position: relative;
        display: flex;
        align-items: center;
        padding: 20px 20px;
        background: $contact-color;
        border-radius: 10px;
        font-size: 22px;
        line-height: 1.5;

        @include mq-sp {
          font-size: 17px;
        }

        &::after {
          content: '';
          position: absolute;
          bottom: -20px;
          left: 50%;
          transform: translateX(-50%);
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 20px 18px 0 18px;
          border-color: $contact-color transparent transparent transparent;
        }
      }

      .number {
        margin-top: 30px;
        padding: 3px 5px 10px 15px;
        background: #fff;
        border: 1px solid #111;
        border-radius: 6px;

        @media screen and (min-width:768px) and (max-width:940px) {
          padding: 3px .53vw 10px 1.59vw;
        }
        @include mq-sp {
          padding: 9px 10px;
        }

        a {
          pointer-events: none;

          @include mq-sp {
            pointer-events: all;
          }
        }

        &__name {
          font-size: 25px;

          @include mq-sp {
            font-size: 20px;
          }
        }

        &__body {
          display: flex;
          align-items: center;
        }

        &__ico {
          width: 51px;
          line-height: 1;

          @media screen and (min-width:768px) and (max-width:940px) {
            width: 5.42vw;
          }
          @include mq-sp {
            width: 41px;
          }

          img {
            width: 100%;

            @include mq-sp {
            }
          }
        }

        &__txt {
          margin-left: 5px;
          font-size: 44px;
          line-height: 1;

          @media screen and (min-width:768px) and (max-width:940px) {
            font-size: 4.68vw;
          }
          @include mq-sp {
            font-size: 35px;
          }
        }
      }
    }
  }
}

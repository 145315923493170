/*--------------------------------------------------------------------------
   #sec-shop
---------------------------------------------------------------------------*/
.sec-shop {
  position: relative;
  z-index: 0;
  padding: 100px 0 70px;
  background-color: #f5f1e5;

	@include mq-sp {
    padding: 50px 0;
	}

  &__hdg {
    margin-bottom: 50px;
    font-size: 32px;
    text-align: center;

    @include mq-sp {
      margin-bottom: 40px;
      font-size: 21px;
    }
  }

  &__wrap {
    padding: 0 20px;

    @include mq-sp {
      padding: 0;
    }

    &:not(:first-of-type) {
      margin-top: 60px;
      padding-top: 60px;
      border-top: 1px solid #111;
    }
  }

  &__fuki {
    position: relative;
    display: inline-block;
    max-width: 815px;
    width: 100%;
    margin-bottom: 50px;
    padding: 10px 30px 10px 50px;
    background: $key-color;
    border: 1px solid #111;
    border-radius: 60px;
    font-size: 22px;

    @include mq-sp {
      padding: 15px 20px;
      border-width: 1.5px;
      border-radius: 10px;
      font-size: 18px;
      line-height: 1.6;
    }

    &::before {
      content: '';
      position: absolute;
      bottom: -21px;
      left: 155px;
      width: 40px;
      height: 22px;
      background: url('../../img/index/shop_arrow_fuki.png') no-repeat 50% 50% / contain;

      @include mq-sp {
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  &__content {
    display: flex;

    @include mq-sp {
      display: block;
    }
  }

  &__body {
    width: 32%;

    @include mq-sp {
      width: 76%;
      margin: 0 auto;
    }
  }

  &__body-inner {
    max-width: 300px;
    margin: 0 auto;
  }

  &__map {
    width: 64%;
    margin-left: auto;

    @include mq-sp {
      width: auto;
      margin: 25px -20px 0;
    }

    iframe {
      width: 100%;
      aspect-ratio: 622 / 456;

      @include mq-sp {
        aspect-ratio: 16 / 9;
      }
    }
  }

  &__img {
    max-width: 275px;

    @include mq-sp {
    }

    img {
      width: 100%;
    }
  }

  &__name {
    margin-top: 10px;
    font-size: 25px;

    @media screen and (min-width:768px) and (max-width:1020px) {
      font-size: 2.45vw;
    }
    @include mq-sp {
      font-size: 21px;
    }
  }

  &__info {
    //margin-top: 5px;
    font-size: 17px;
    line-height: 1.5;

    @media screen and (min-width:768px) and (max-width:1020px) {
      font-size: 1.66vw;
    }
    @include mq-sp {
      font-size: 16px;
    }
  }

  &__tel {
    margin-top: 10px;
    padding: 3px 5px 6px 10px;
    background: #fff;
    border: 1px solid #111;
    border-radius: 5px;

    @include mq-sp {
      margin: 10px -10% 0;
      padding: 9px 10px;
    }

    a {
      pointer-events: none;

      @include mq-sp {
        pointer-events: all;
      }
    }

    &-txt {
      font-size: 16px;

      @include mq-sp {
        font-size: 15px;
      }
    }

    &-number {
      display: flex;
      align-items: center;

      .ico {
        line-height: 1;

        @media screen and (min-width:768px) and (max-width:1020px) {
          width: 3.72vw;
        }

        img {
          max-width: 100%;

          @media screen and (min-width:768px) and (max-width:1020px) {
            width: 100%;
          }
          @include mq-sp {
            width: 41px;
          }
        }
      }

      .txt {
        margin-left: 5px;
        font-size: 33px;
        line-height: 1;

        @media screen and (min-width:768px) and (max-width:1020px) {
          font-size: 3.23vw;
        }
      }
    }
  }

  &__sub {
    display: flex;
    margin-top: 50px;

    @include mq-sp {
      display: block;
    }
  }

  &__office {
    max-width: 422px;
    width: 43.9%;

    @media screen and (min-width:768px) and (max-width:1020px) {
      width: 48%;
    }
    @include mq-sp {
      max-width: none;
      width: 100%;
      margin-top: 50px;
    }

    & + .sec-shop__office {
      margin-left: auto;

      @include mq-sp {
        margin: 40px 0 0;
      }
    }

    &-info {
      display: flex;
      margin-bottom: 15px;

      @include mq-sp {
        display: block;
      }
    }

    &-name {
      font-size: 19px;
      line-height: 1.5;

      @media screen and (min-width:768px) and (max-width:940px) {
        font-size: 2vw;
      }
    }

    &-add {
      margin-left: 60px;
      font-size: 13px;

      @media screen and (min-width:768px) and (max-width:940px) {
        margin-left: 10px;
      }
      @include mq-sp {
        margin: 10px 0 0;
        font-size: 17px;
      }
    }

    &-map {
      @include mq-sp {
        margin: 0 -20px;
      }

      iframe {
        width: 100%;
        aspect-ratio: 422 / 208;

        @include mq-sp {
          aspect-ratio: 16 / 9;
        }
      }
    }
  }

  &__area {
    margin-top: 40px;

    @include mq-sp {
      //margin-top: 20px;
    }

    &-lead {
      font-size: 22px;
      line-height: 1.5;

      @include mq-sp {
        font-size: 18px;
      }

      span {
        display: block;

        @include mq-sp {
          font-size: 22px;
          text-align: center;
        }
      }
    }

    &-txt {
      font-size: 17px;
      line-height: 1.5;

      @include mq-sp {
        font-size: 16px;
      }

      &:not(:first-of-type) {
        margin-top: 1em;
      }
    }
  }
}

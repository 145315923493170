/*==========================================================================

   mixin

===========================================================================*/
/*--------------------------------------------------------------------------
   overwrite
---------------------------------------------------------------------------*/
/*--------------------------------------------------------------------------
   common
---------------------------------------------------------------------------*/
/* .head-btn
-----------------------------------------------------------------*/
.head-btn .nav {
  display: flex;
  flex-direction: row-reverse;
}
@media only screen and (max-width: 767px) {
  .head-btn .nav {
    display: block;
  }
}
.head-btn .nav .item {
  width: 100%;
}
.head-btn .nav .item a {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  font-size: 25px;
  font-weight: 700;
  transition: opacity 0.2s;
}
.head-btn .nav .item a:hover {
  opacity: 0.5;
}
@media screen and (min-width: 768px) and (max-width: 1500px) {
  .head-btn .nav .item a {
    font-size: 1.5vw;
  }
}
@media only screen and (max-width: 767px) {
  .head-btn .nav .item a {
    padding: 20px;
    justify-content: flex-start;
    font-size: 4.2vw;
  }
  .head-btn .nav .item a::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #fff;
  }
  .head-btn .nav .item a::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 29px;
    transform: translateY(-50%) rotate(180deg);
    width: 15px;
    height: 8px;
    background: url("../../img/common/ico_arrow_select.svg") no-repeat 50% 50%/cover;
    transition: 0.3s;
  }
}
.head-btn .nav .item--kaitori {
  background-color: #FFF675;
}
.head-btn .nav .item--kaitori a::after {
  top: 42%;
  right: 27px;
  rotate: 180deg;
}
.head-btn .nav .item--owner {
  position: relative;
  background-color: #3A82CD;
}
.head-btn .nav .item--owner a {
  color: #fff;
}
.head-btn .nav .item--owner a::after {
  top: 47%;
  right: 29px;
  rotate: 90deg;
}
.head-btn .nav .item--owner::before {
  position: absolute;
  top: 0;
  left: -20px;
  width: 100%;
  height: 100%;
  background-color: #3A82CD;
  transform: skewX(160deg);
  content: "";
}

/*--------------------------------------------------------------------------
   #sec-mv
---------------------------------------------------------------------------*/
.sec-mv {
  position: relative;
  z-index: 0;
}
.sec-mv__content {
  position: relative;
  min-height: 566px;
  background: #f5f1e5 url("../../img/index/mv_bg.png") no-repeat 50% 100%/contain;
}
@media only screen and (max-width: 767px) {
  .sec-mv__content {
    padding-bottom: 30px;
    background: #f5f1e5;
  }
}
.sec-mv__slide {
  position: relative;
  max-width: 1350px;
  height: 566px;
  padding: 20px 10px 0;
  margin: auto auto 0;
  z-index: 3;
  pointer-events: none;
}
@media only screen and (max-width: 767px) {
  .sec-mv__slide {
    max-width: none;
    height: auto;
    padding: 0 10px 80px;
    background: url("../../img/index/mv_bg_sp.png") no-repeat 50% 100%/contain;
    pointer-events: all;
  }
}
.sec-mv__slide .swiper {
  height: 100%;
}
@media only screen and (max-width: 767px) {
  .sec-mv__slide .swiper {
    overflow: visible;
  }
}
.sec-mv__slide .swiper-slide {
  min-height: 506px;
  pointer-events: none;
}
.sec-mv__slide .swiper-slide__img {
  height: 100%;
}
.sec-mv__slide .swiper-slide__img img {
  position: absolute;
  bottom: 0;
  max-width: 100%;
}
@media screen and (min-width: 951px) and (max-width: 1300px) {
  .sec-mv__slide .swiper-slide__img img {
    width: calc(100vw - 406px);
    height: auto;
  }
}
@media screen and (min-width: 861px) and (max-width: 950px) {
  .sec-mv__slide .swiper-slide__img img {
    width: auto;
    height: 100%;
  }
}
@media screen and (min-width: 768px) and (max-width: 860px) {
  .sec-mv__slide .swiper-slide__img img {
    width: calc(100% - 400px);
    height: auto;
  }
}
@media only screen and (max-width: 767px) {
  .sec-mv__slide .swiper-slide__img img {
    position: static;
    width: 100%;
    height: auto;
  }
}
.sec-mv__slide .swiper-pagination {
  display: none;
}
@media only screen and (max-width: 767px) {
  .sec-mv__slide .swiper-pagination {
    display: block;
    position: absolute;
    bottom: -30px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.sec-mv__slide .swiper-pagination-bullet {
  width: 13px;
  height: 13px;
  margin: 0 5px !important;
  border-radius: 50%;
  border: 1px solid #111;
  background: #fff;
  transition: 0.3s;
  opacity: 1;
}
.sec-mv__slide .swiper-pagination-bullet:hover {
  background: #fff69c;
}
.sec-mv__slide .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #111;
}
.sec-mv__request {
  position: absolute;
  top: 60px;
  left: calc(50% + 260px);
  width: 384px;
  height: 456px;
  padding: 60px 30px 0;
  background: url("../../img/index/mv_bg_select.png") no-repeat 50% 50%/contain;
  z-index: 2;
}
@media screen and (min-width: 768px) and (max-width: 1300px) {
  .sec-mv__request {
    left: auto;
    right: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-mv__request {
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    max-width: 339px;
    width: 100%;
    margin: 0 auto;
    z-index: 999;
  }
}
.sec-mv__request .m-request-select__item:not(:first-of-type) {
  margin-top: 22px;
}
.sec-mv__request .m-request-select__item .c-select {
  height: 57px;
}
@media only screen and (max-width: 767px) {
  .sec-mv__request .m-request-select__item .c-select {
    height: 50px;
  }
}
.sec-mv__request .c-btn-request {
  width: 100%;
  height: 56px;
  margin-top: 20px;
}
.sec-mv__request-txt01 {
  position: absolute;
  top: -30px;
  left: -20px;
}
.sec-mv__request-txt02 {
  margin-bottom: 10px;
  font-size: 32px;
  text-align: center;
}
.sec-mv__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}
@media only screen and (max-width: 767px) {
  .sec-mv__list {
    display: none;
  }
}
.sec-mv__list-item {
  display: inline-block;
  padding: 5px 20px;
  margin-bottom: 15px;
  background: #f5f1e5;
  border-radius: 18px;
  font-size: 18px;
  color: #111;
}
.sec-mv__list-item:not(:first-of-type) {
  margin-left: 16px;
}

/*--------------------------------------------------------------------------
   #sec-intro
---------------------------------------------------------------------------*/
.sec-intro {
  position: relative;
  z-index: 0;
  padding: 50px 0 70px;
}
.sec-intro__quocard {
  position: relative;
  max-width: 924px;
  margin: 20px auto 0;
  padding: 24px 25px 15px 23px;
  border: 2px solid #111;
  border-radius: 10px;
}
@media only screen and (max-width: 1000px) {
  .sec-intro__quocard {
    width: 90%;
  }
}
.sec-intro__quocard-hdg {
  margin-right: 70px;
}
@media only screen and (max-width: 767px) {
  .sec-intro__quocard-hdg {
    margin-right: 0;
  }
}
.sec-intro__quocard-hdg img {
  max-width: 100%;
}
@media only screen and (max-width: 767px) {
  .sec-intro__quocard-hdg img {
    margin-top: 30px;
  }
}
.sec-intro__quocard-obj {
  position: absolute;
  top: -20px;
  right: 10px;
}
@media only screen and (max-width: 767px) {
  .sec-intro__quocard-obj {
    top: -35px;
    right: -20px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-intro__quocard-obj img {
    width: 94px;
  }
}
.sec-intro__quocard-caption {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.3;
}
.sec-intro__quocard-limit {
  margin-top: -85px;
  margin-left: -65px;
}
.sec-intro__bnrs {
  display: flex;
  align-items: end;
  justify-content: center;
  margin-top: 30px;
}
@media only screen and (max-width: 767px) {
  .sec-intro__bnrs {
    display: block;
    margin-top: 0;
  }
}
.sec-intro__bnr {
  margin: 0 15px;
}
@media only screen and (max-width: 767px) {
  .sec-intro__bnr {
    margin: 15px 0 0;
  }
}
.sec-intro__bnr img {
  max-width: 100%;
}
@media only screen and (max-width: 767px) {
  .sec-intro__bnr img {
    width: 100%;
  }
}

/*--------------------------------------------------------------------------
   #sec-worry
---------------------------------------------------------------------------*/
.sec-worry {
  position: relative;
  z-index: 0;
  padding: 100px 0;
  background-color: #f5f1e5;
}
@media only screen and (max-width: 767px) {
  .sec-worry {
    padding: 50px 0;
  }
}
.sec-worry__header {
  position: relative;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-worry__icons {
    display: flex;
    align-items: end;
    justify-content: center;
  }
}
.sec-worry__ico {
  position: absolute;
  top: 20px;
}
@media only screen and (max-width: 767px) {
  .sec-worry__ico {
    position: relative;
    top: auto;
    transform: scale(0.8);
  }
}
.sec-worry__ico--left {
  right: calc(50% + 250px);
}
@media only screen and (max-width: 767px) {
  .sec-worry__ico--left {
    right: auto;
  }
}
.sec-worry__ico--right {
  left: calc(50% + 250px);
}
@media only screen and (max-width: 767px) {
  .sec-worry__ico--right {
    left: 16px;
  }
}
.sec-worry__hdg {
  font-size: 32px;
}
@media only screen and (max-width: 767px) {
  .sec-worry__hdg {
    margin-top: 10px;
    font-size: 22px;
  }
}
.sec-worry__hdg span {
  display: block;
}
.sec-worry__hdg .txt02 {
  color: #ff6964;
}
.sec-worry__slide {
  margin-top: 50px;
}
@media only screen and (max-width: 767px) {
  .sec-worry__slide {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-worry__slide.m-slide {
    max-width: none;
  }
}
.sec-worry__slide .swiper-slide {
  height: auto;
}
.sec-worry__slide .swiper-slide__img {
  position: relative;
  height: 100%;
}
.sec-worry__slide .swiper-slide__img img {
  width: 100%;
  height: auto;
}
.sec-worry__slide .swiper-slide__btn {
  position: absolute;
  left: 50%;
  bottom: 68px;
  width: 74%;
  transform: translate(-50%, 0);
  margin-top: 10px;
  font-weight: 700;
}
.sec-worry__slide .swiper-slide__btn.c-btn-request {
  height: 46px;
  font-size: 17px;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .sec-worry__slide .swiper-slide__btn.c-btn-request {
    bottom: 8%;
    font-size: 1.7vw;
  }
}
@media only screen and (max-width: 767px) {
  .sec-worry__slide .swiper-slide__btn.c-btn-request {
    bottom: 8%;
    height: 12vw;
    font-size: 3.4vw;
  }
}
.sec-worry__slide .swiper-slide__btn.c-btn-request a {
  border: 1px solid #fff;
  border-radius: 1000px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}
.sec-worry__txt {
  margin-top: 50px;
  font-size: 24px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-worry__txt {
    margin-top: 5px;
    font-size: 18px;
  }
}
.sec-worry__btn {
  width: 432px;
  height: 78px;
  margin: 30px auto 0;
  font-size: 23px;
}
@media only screen and (max-width: 767px) {
  .sec-worry__btn {
    width: 90%;
    height: 50px;
    margin-top: 10px;
    font-size: 16px;
  }
}
.sec-worry__btn.c-btn-request a {
  border-radius: 40px;
  box-shadow: 7px 7px 0 rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 767px) {
  .sec-worry__btn.c-btn-request a {
    box-shadow: 5px 5px 0 rgba(0, 0, 0, 0.2);
  }
}

/*--------------------------------------------------------------------------
   #sec-news
---------------------------------------------------------------------------*/
.sec-news {
  position: relative;
  z-index: 0;
  padding: 90px 0;
}
@media only screen and (max-width: 767px) {
  .sec-news {
    padding: 40px 0;
  }
}
.sec-news__content {
  position: relative;
  max-width: 900px;
  margin: 0 auto;
  padding: 55px 60px 60px;
  border: 8px solid #e7e7e7;
  border-radius: 18px;
}
@media only screen and (max-width: 767px) {
  .sec-news__content {
    border-width: 4px;
    padding: 20px 20px 30px;
  }
}
.sec-news__hdg {
  font-size: 32px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-news__hdg {
    font-size: 21px;
  }
}
.sec-news__list {
  margin-top: 10px;
}
@media only screen and (max-width: 767px) {
  .sec-news__list {
    margin-top: 0;
  }
}
.sec-news__item {
  padding: 8px 0;
  font-size: 20px;
  font-weight: 500;
  border-bottom: 1px solid #111;
}
@media only screen and (max-width: 767px) {
  .sec-news__item {
    padding: 14px 0;
    font-size: 14px;
  }
}

/*--------------------------------------------------------------------------
   #sec-which
---------------------------------------------------------------------------*/
.sec-which {
  position: relative;
  z-index: 0;
  padding: 100px 0 70px;
  background-color: #f5f1e5;
}
@media only screen and (max-width: 767px) {
  .sec-which {
    padding: 50px 0 30px;
  }
}
.sec-which__header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .sec-which__header {
    flex-direction: column;
  }
}
.sec-which__header-ico {
  margin-left: -70px;
}
@media only screen and (max-width: 767px) {
  .sec-which__header-ico {
    margin-left: 0;
  }
}
.sec-which__header-ico img {
  max-width: 100%;
}
@media only screen and (max-width: 767px) {
  .sec-which__header-ico img {
    width: 82px;
  }
}
.sec-which__hdg {
  margin-left: 30px;
  font-size: 32px;
}
@media only screen and (max-width: 767px) {
  .sec-which__hdg {
    margin: 10px 0 20px;
    font-size: 23px;
    line-height: 1.3;
    text-align: center;
  }
}
.sec-which__content {
  max-width: 770px;
  margin: 0 auto 75px;
  padding: 30px 95px 40px;
  background: #fff;
  border-radius: 10px;
}
@media only screen and (max-width: 767px) {
  .sec-which__content {
    margin: 0 auto 20px;
    padding: 30px 15px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-which__content--kaitori .sec-which__content-img {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
  }
}
.sec-which__content-hdg {
  font-size: 26px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-which__content-hdg {
    font-size: 21px;
  }
}
.sec-which__content-txt {
  margin-top: 20px;
  font-size: 20px;
}
@media only screen and (max-width: 767px) {
  .sec-which__content-txt {
    margin-top: 15px;
    font-size: 16px;
  }
}
.sec-which__content-txt span {
  background: linear-gradient(to bottom, #fff 0, #fff 70%, #fff271 70%, #fff271 100%);
}
.sec-which__content-img {
  margin-top: 25px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-which__content-img {
    margin-top: 20px;
  }
}
.sec-which__content-img img {
  max-width: 100%;
}
@media only screen and (max-width: 767px) {
  .sec-which__content-img img {
    width: 100%;
  }
}

/*--------------------------------------------------------------------------
   #sec-merit
---------------------------------------------------------------------------*/
.sec-merit {
  position: relative;
  z-index: 0;
  padding: 0 0 70px;
  background-color: #f5f1e5;
}
@media only screen and (max-width: 767px) {
  .sec-merit {
    padding-bottom: 30px;
  }
}
.sec-merit__header {
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
}
@media only screen and (max-width: 767px) {
  .sec-merit__header {
    flex-direction: column;
  }
}
.sec-merit__header-ico {
  margin-right: -70px;
}
@media only screen and (max-width: 767px) {
  .sec-merit__header-ico {
    margin-right: 0;
  }
}
.sec-merit__header-ico img {
  max-width: 100%;
}
@media only screen and (max-width: 767px) {
  .sec-merit__header-ico img {
    width: 82px;
  }
}
.sec-merit__hdg {
  margin-right: 30px;
  font-size: 32px;
}
@media only screen and (max-width: 767px) {
  .sec-merit__hdg {
    margin: 10px 0 20px;
    font-size: 23px;
    line-height: 1.3;
    text-align: center;
  }
}
.sec-merit__content {
  margin-bottom: 70px;
}
@media only screen and (max-width: 767px) {
  .sec-merit__content {
    margin-bottom: 40px;
  }
}
.sec-merit__content-hdg {
  text-align: center;
}
.sec-merit__content-hdg span {
  display: inline-block;
  padding: 0 30px;
  border-radius: 5px;
  line-height: 55px;
}
@media only screen and (max-width: 767px) {
  .sec-merit__content-hdg span {
    display: block;
    padding: 5px;
    line-height: normal;
  }
}
.sec-merit__content-hdg--chukai span {
  font-size: 28px;
  background: #b1e3e9;
}
@media only screen and (max-width: 767px) {
  .sec-merit__content-hdg--chukai span {
    font-size: 22px;
  }
}
.sec-merit__content-hdg--kaitori span {
  font-size: 24px;
  background: #fff69c;
}
@media only screen and (max-width: 767px) {
  .sec-merit__content-hdg--kaitori span {
    font-size: 22px;
  }
}
.sec-merit__slide {
  margin-top: 45px;
}
@media only screen and (max-width: 767px) {
  .sec-merit__slide {
    margin-top: 20px;
  }
}
.sec-merit__slide .swiper-slide__img img {
  width: 100%;
  height: auto;
}
.sec-merit__content-body {
  display: flex;
  margin-top: 60px;
  padding: 50px 0;
  background: #fff url("../../img/common/bg_grid_blue.png") repeat 0 0/24px 24px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
}
@media only screen and (max-width: 767px) {
  .sec-merit__content-body {
    display: block;
    margin-top: 30px;
    padding: 40px 20px;
    background: #fff url("../../img/common/bg_grid_blue.png") repeat 0 0/18px 18px;
  }
}
.sec-merit__content-body img {
  max-width: 100%;
}
@media only screen and (max-width: 767px) {
  .sec-merit__content-body img {
    width: 100%;
  }
}
.sec-merit__point {
  width: 50%;
  padding-right: 10px;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .sec-merit__point {
    padding-right: 0.1vw;
  }
}
@media only screen and (max-width: 767px) {
  .sec-merit__point {
    width: auto;
    padding-right: 0;
  }
}
.sec-merit__point-hdg {
  font-size: 27px;
  margin-bottom: 6px;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .sec-merit__point-hdg {
    font-size: 2.7vw;
  }
}
@media only screen and (max-width: 767px) {
  .sec-merit__point-hdg {
    display: flex;
    font-size: 20px;
  }
}
.sec-merit__point-hdg span {
  position: relative;
  top: 2px;
  margin-right: 8px;
  display: inline-block;
  line-height: 1;
}
@media only screen and (max-width: 767px) {
  .sec-merit__point-hdg span {
    top: auto;
    margin-right: 5px;
    padding-top: 5px;
  }
}
.sec-merit__point-item {
  font-size: 20px;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .sec-merit__point-item {
    font-size: 2vw;
  }
}
@media only screen and (max-width: 767px) {
  .sec-merit__point-item {
    font-size: 17px;
  }
}
.sec-merit__point-item span {
  display: block;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.6;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .sec-merit__point-item span {
    font-size: 1.7vw;
  }
}
.sec-merit__point--merit {
  padding-left: 50px;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .sec-merit__point--merit {
    padding-left: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-merit__point--merit {
    padding-left: 0;
  }
}
.sec-merit__point--merit .sec-merit__point-hdg span {
  top: 1px;
}
@media only screen and (max-width: 767px) {
  .sec-merit__point--merit .sec-merit__point-hdg span {
    top: 0;
  }
}
.sec-merit__point--merit .sec-merit__point-hdg span img {
  width: 26px;
}
@media only screen and (max-width: 767px) {
  .sec-merit__point--merit .sec-merit__point-hdg span img {
    width: 22px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-merit__point--demerit {
    margin-top: 40px;
  }
}
.sec-merit__point--demerit .sec-merit__point-hdg span img {
  width: 26px;
}
@media only screen and (max-width: 767px) {
  .sec-merit__point--demerit .sec-merit__point-hdg span img {
    width: 22px;
  }
}

/*--------------------------------------------------------------------------
   #sec-type
---------------------------------------------------------------------------*/
.sec-type {
  position: relative;
  z-index: 0;
  padding-bottom: 105px;
  background-color: #f5f1e5;
}
@media only screen and (max-width: 767px) {
  .sec-type {
    padding-bottom: 30px;
  }
}
.sec-type__content {
  position: relative;
  max-width: 990px;
  margin: 0 auto;
  padding: 60px 84px 80px;
  background: #fff;
  border-radius: 10px;
}
@media screen and (min-width: 768px) and (max-width: 1020px) {
  .sec-type__content {
    padding: 5.88vw 8.23vw 80px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-type__content {
    padding: 30px 15px 0;
  }
}
.sec-type__hdg {
  font-size: 30px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-type__hdg {
    font-size: 21px;
  }
}
.sec-type__way {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  margin: 50px 0 100px;
}
@media only screen and (max-width: 767px) {
  .sec-type__way {
    flex-direction: column;
    margin: 30px 0 40px;
  }
}
.sec-type__way-item {
  width: 368px;
}
@media only screen and (max-width: 767px) {
  .sec-type__way-item {
    width: auto;
  }
}
.sec-type__way-item:nth-child(2) {
  margin-left: 10px;
}
@media only screen and (max-width: 767px) {
  .sec-type__way-item:nth-child(2) {
    margin: 30px 0 0;
  }
}
.sec-type__way-item img {
  width: 100%;
}
.sec-type__txt01 {
  font-size: 31px;
  text-align: center;
  line-height: 1.5;
}
@media only screen and (max-width: 767px) {
  .sec-type__txt01 {
    font-size: 25px;
  }
}
.sec-type__txt02 {
  position: relative;
  font-size: 33px;
  text-align: center;
  line-height: 1.5;
  z-index: 0;
}
@media only screen and (max-width: 767px) {
  .sec-type__txt02 {
    font-size: 26px;
  }
}
.sec-type__txt02 .em {
  color: #ff6964;
}
.sec-type__txt02--cross {
  margin: 40px 0 30px;
}
@media only screen and (max-width: 767px) {
  .sec-type__txt02--cross {
    margin: 30px 0;
  }
}
.sec-type__txt02--cross::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 132px;
  height: 132px;
  background: url("../../img/index/type_ico_cross.svg") no-repeat 50% 50%/cover;
  z-index: -1;
}
@media only screen and (max-width: 767px) {
  .sec-type__txt02--cross::before {
    width: 96px;
    height: 96px;
  }
}
.sec-type__txt02--circle {
  margin: 70px 0 50px;
}
@media only screen and (max-width: 767px) {
  .sec-type__txt02--circle {
    margin: 40px 0 30px;
  }
}
.sec-type__txt02--circle::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 156px;
  height: 156px;
  background: url("../../img/index/type_ico_circle.svg") no-repeat 50% 50%/cover;
  z-index: -1;
}
@media only screen and (max-width: 767px) {
  .sec-type__txt02--circle::before {
    width: 115px;
    height: 115px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-type__icons {
    display: flex;
    align-items: end;
    margin-top: 40px;
  }
}
.sec-type__ico {
  position: absolute;
  bottom: -80px;
}
@media only screen and (max-width: 767px) {
  .sec-type__ico {
    position: relative;
    bottom: auto;
  }
}
.sec-type__ico img {
  max-width: 100%;
}
.sec-type__ico--left {
  left: -22px;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .sec-type__ico--left {
    left: -60px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-type__ico--left {
    left: auto;
    width: 155px;
  }
}
.sec-type__ico--right {
  right: -19px;
}
@media screen and (min-width: 768px) and (max-width: 900px) {
  .sec-type__ico--right {
    right: -45px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-type__ico--right {
    right: auto;
    width: 125px;
    margin: 0 10px 0 auto;
  }
}

/*--------------------------------------------------------------------------
   #sec-area
---------------------------------------------------------------------------*/
.sec-area {
  position: relative;
  z-index: 0;
  padding: 100px 0;
}
@media only screen and (max-width: 767px) {
  .sec-area {
    padding: 50px 0 80px;
  }
}
.sec-area__hdg {
  font-size: 32px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-area__hdg {
    font-size: 21px;
  }
}
.sec-area__lead {
  margin-top: 15px;
  font-size: 24px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-area__lead {
    font-size: 16px;
  }
}
.sec-area__txt {
  margin-top: 15px;
  font-size: 24px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-area__txt {
    margin-top: 10px;
    font-size: 16px;
  }
}
.sec-area__txt span {
  background: linear-gradient(to bottom, #fff 0, #fff 70%, #fff271 70%, #fff271 100%);
}
.sec-area__content {
  margin-top: 50px;
  padding: 50px 70px;
  background: #f5f1e5;
  border-radius: 30px;
}
@media screen and (min-width: 768px) and (max-width: 1020px) {
  .sec-area__content {
    padding: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-area__content {
    margin-top: 30px;
    padding: 0px 20px;
    border-radius: 6px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-area__content--mansion .sec-area__header-ico img {
    width: 32px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-area__content--mansion .sec-area__header-hdg {
    margin-left: -32px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-area__content--house .sec-area__header-ico img {
    width: 41px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-area__content--house .sec-area__header-hdg {
    margin-left: -41px;
  }
}
.sec-area__content--land {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .sec-area__content--land {
    display: block;
  }
}
.sec-area__content--land .sec-area__header {
  width: 16%;
}
@media screen and (min-width: 768px) and (max-width: 1020px) {
  .sec-area__content--land .sec-area__header {
    width: 12%;
  }
}
@media only screen and (max-width: 767px) {
  .sec-area__content--land .sec-area__header {
    width: auto;
  }
}
@media only screen and (max-width: 767px) {
  .sec-area__content--land .sec-area__header-ico img {
    width: 44px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-area__content--land .sec-area__header-hdg {
    margin-left: -44px;
  }
}
.sec-area__content--land .sec-area__body-wrap {
  width: 84%;
  margin: 0;
}
@media screen and (min-width: 768px) and (max-width: 1020px) {
  .sec-area__content--land .sec-area__body-wrap {
    width: 88%;
  }
}
@media only screen and (max-width: 767px) {
  .sec-area__content--land .sec-area__body-wrap {
    width: auto;
  }
}
.sec-area__header {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* SP open */
}
@media only screen and (max-width: 767px) {
  .sec-area__header {
    flex-direction: row;
    justify-content: flex-start;
    padding: 20px 0;
  }
  .sec-area__header::before {
    content: "";
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #111;
  }
  .sec-area__header::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 9px;
    transform: translateY(-50%) rotate(180deg);
    width: 11px;
    height: 6px;
    background: url("../../img/index/qa_ico_arrow.svg") no-repeat 50% 50%/cover;
    transition: 0.3s;
  }
}
.sec-area__header-hdg {
  margin-top: 20px;
  font-size: 27px;
}
@media only screen and (max-width: 767px) {
  .sec-area__header-hdg {
    margin-top: 0;
    font-size: 18px;
    width: 100%;
    text-align: center;
  }
  .sec-area__header-hdg .mi-sp {
    display: inline-block;
  }
}
@media only screen and (max-width: 767px) {
  .sec-area__header.is-open::after {
    transform: translateY(-50%) rotate(0deg);
  }
}
@media only screen and (max-width: 767px) {
  .sec-area__body {
    padding: 5px 0 25px;
  }
}
.sec-area__body-wrap {
  margin-top: 40px;
}
@media only screen and (max-width: 767px) {
  .sec-area__body-wrap {
    margin-top: 0;
    height: 0;
    overflow: hidden;
    transition: height 0.3s;
  }
}
.sec-area__body-hdg {
  margin: 50px 0 30px;
  font-size: 21px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-area__body-hdg {
    margin: 30px 0 15px;
    font-size: 24px;
  }
}
.sec-area__body-txt {
  font-size: 17px;
  line-height: 1.6;
}
@media only screen and (max-width: 767px) {
  .sec-area__body-txt {
    font-size: 16px;
  }
}
.sec-area__body-txt + .sec-area__land-hdg {
  margin-top: 1.2em;
}
.sec-area__land-hdg {
  font-size: 17px;
}
@media only screen and (max-width: 767px) {
  .sec-area__land-hdg {
    font-size: 16px;
  }
}
.sec-area__land-hdg {
  font-size: 17px;
}
@media only screen and (max-width: 767px) {
  .sec-area__land-hdg {
    font-size: 16px;
  }
}
.sec-area__inquiry {
  display: flex;
  padding-left: 10%;
}
@media screen and (min-width: 768px) and (max-width: 1020px) {
  .sec-area__inquiry {
    padding-left: 5%;
  }
}
@media only screen and (max-width: 767px) {
  .sec-area__inquiry {
    display: block;
    padding-left: 0;
  }
}
.sec-area__inquiry-item {
  width: 50%;
}
@media only screen and (max-width: 767px) {
  .sec-area__inquiry-item {
    width: auto;
  }
}
.sec-area__inquiry-item--tel {
  margin-left: 5%;
}
@media only screen and (max-width: 767px) {
  .sec-area__inquiry-item--tel {
    margin: 20px 0 0;
  }
}
.sec-area__inquiry-item--tel .sec-area__inquiry-txt {
  padding-left: 10px;
}
.sec-area__inquiry-txt {
  display: flex;
  align-items: end;
  font-size: 17px;
  line-height: 1.5;
}
.sec-area__inquiry-btn {
  max-width: 300px;
  width: 100%;
  margin-top: 10px;
}
.sec-area__inquiry-btn.c-btn-request {
  height: 46px;
  font-size: 17px;
}
@media only screen and (max-width: 767px) {
  .sec-area__inquiry-btn.c-btn-request {
    height: 70px;
  }
}
.sec-area__inquiry-btn.c-btn-request a {
  border: 1px solid #fff;
  border-radius: 40px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}
.sec-area__inquiry-btn.c-btn-tel {
  height: 46px;
  font-size: 17px;
}
@media only screen and (max-width: 767px) {
  .sec-area__inquiry-btn.c-btn-tel {
    height: 70px;
  }
}
.sec-area__inquiry-btn.c-btn-tel a {
  border: 1px solid #fff;
  border-radius: 40px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}
.sec-area__map {
  margin-top: 80px;
}
@media only screen and (max-width: 767px) {
  .sec-area__map {
    margin-top: 50px;
  }
}
.sec-area__map-hdg {
  font-size: 23px;
  text-align: center;
}
.sec-area__map-lead {
  margin-top: 10px;
  font-size: 17px;
  text-align: center;
}
.sec-area__map-img {
  margin-top: 50px;
  text-align: center;
}
.sec-area__map-img svg {
  max-width: 100%;
}
.sec-area__map-img svg path {
  pointer-events: none;
}
.sec-area__map-img svg a path {
  pointer-events: all;
  transition: fill 0.2s, stroke 0s;
}
.sec-area__map-img svg a:hover #path-kyoto path {
  fill: #E7E7E7;
}
.sec-area__map-img svg a:hover #path-hyogo path {
  fill: #E7E7E7;
  stroke: #E7E7E7 !important;
}
.sec-area__map-img svg a:hover #path-osaka path {
  fill: #E7E7E7;
}
.sec-area__map-img svg a:hover #path-nara path {
  fill: #E7E7E7;
  transition: fill 0.2s, stroke 0s;
}
.sec-area__map-img svg a:hover #path-wakayama path {
  fill: #E7E7E7;
}
.sec-area__slide .swiper-slide__img img {
  width: 100%;
  height: auto;
}
.sec-area__slide .swiper-button-prev {
  margin-left: -10px;
}
.sec-area__slide .swiper-button-next {
  margin-right: -10px;
}
.sec-area__slide .swiper[data-id="1"] .swiper-button-prev,
.sec-area__slide .swiper[data-id="1"] .swiper-button-next {
  top: calc(50% + 7vw);
}

/*--------------------------------------------------------------------------
   #sec-area-popup
---------------------------------------------------------------------------*/
.sec-area-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.6s ease-in-out;
}
@media only screen and (max-width: 767px) {
  .sec-area-popup {
    display: none;
  }
}
.sec-area-popup .sec-area-popup__overlay {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.85);
}
.sec-area-popup__body {
  position: fixed;
  top: calc(50% + 50px);
  left: 50%;
  transform: translate(-50%, -50%);
  width: 578px;
  padding-top: 35px;
  background-color: #fff;
  border: 1px solid #d0cfcf;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.6s ease-in-out;
}
.sec-area-popup__body .swiper {
  overflow: hidden;
}
.sec-area-popup__body .swiper-slide__img img {
  width: 100%;
  height: auto;
}
.sec-area-popup__close {
  position: absolute;
  top: -20px;
  right: -20px;
  z-index: 2;
}
.sec-area-popup__close button {
  position: relative;
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  transition: 0.3s;
  background-color: #000;
}
.sec-area-popup__close button::before, .sec-area-popup__close button::after {
  display: inline-block;
  position: absolute;
  left: 0;
  width: 24px;
  height: 2px;
  transition: all 0.4s;
  box-sizing: border-box;
  background-color: #fff;
  content: "";
  transform: translate(8px, 0px) rotate(-45deg);
}
.sec-area-popup__close button::after {
  transform: translate(8px, 0px) rotate(45deg);
}
.sec-area-popup__close button:hover {
  transform: scale(1.2);
}

.sec-area-popup.is-show {
  pointer-events: all;
  visibility: visible;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
.sec-area-popup.is-show .sec-area-popup__body {
  pointer-events: all;
  visibility: visible;
  opacity: 1;
  transition: all 0.6s ease-in-out;
  transition-delay: 0.3s;
}

/*--------------------------------------------------------------------------
   #sec-flow
---------------------------------------------------------------------------*/
.sec-flow {
  position: relative;
  z-index: 0;
  padding: 130px 0 140px;
  background-color: #fff69c;
}
@media only screen and (max-width: 767px) {
  .sec-flow {
    padding: 50px 0 55px;
  }
}
.sec-flow__hdg {
  font-size: 32px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-flow__hdg {
    font-size: 21px;
  }
}
.sec-flow__slide {
  margin-top: 65px;
}
@media only screen and (max-width: 767px) {
  .sec-flow__slide {
    margin-top: 30px;
  }
}
.sec-flow__slide .swiper-slide {
  width: 434px !important;
  background-color: #fff;
}
@media screen and (min-width: 768px) and (max-width: 1080px) {
  .sec-flow__slide .swiper-slide {
    width: 40vw !important;
  }
}
@media only screen and (max-width: 767px) {
  .sec-flow__slide .swiper-slide {
    width: 100% !important;
  }
}
@media only screen and (max-width: 767px) {
  .sec-flow__slide.m-slide {
    max-width: calc(100vw - 40px);
  }
  .sec-flow__slide.m-slide .swiper-button-prev {
    margin-left: -28px;
  }
  .sec-flow__slide.m-slide .swiper-button-next {
    margin-right: -28px;
  }
}
.sec-flow__item {
  padding: 50px;
  background-color: #fff;
}
@media screen and (min-width: 768px) and (max-width: 1080px) {
  .sec-flow__item {
    padding: 4.62vw;
  }
}
@media only screen and (max-width: 767px) {
  .sec-flow__item {
    min-height: 395px;
    padding: 30px 20px;
  }
}
.sec-flow__item-header {
  display: flex;
  align-items: center;
}
.sec-flow__item-number {
  font-family: "Anton", sans-serif;
  font-size: 46px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 768px) and (max-width: 1080px) {
  .sec-flow__item-number {
    font-size: 4.25vw;
  }
}
@media only screen and (max-width: 767px) {
  .sec-flow__item-number {
    font-size: 40px;
  }
}
.sec-flow__item-hdg {
  margin-left: 20px;
  font-size: 27px;
}
@media screen and (min-width: 768px) and (max-width: 1080px) {
  .sec-flow__item-hdg {
    margin-left: 1.85vw;
    font-size: 2.5vw;
  }
}
@media only screen and (max-width: 767px) {
  .sec-flow__item-hdg {
    font-size: 22px;
  }
}
.sec-flow__item-img {
  margin-top: 25px;
}
.sec-flow__item-img img {
  width: 100%;
  height: auto;
}
.sec-flow__item-txt {
  margin-top: 20px;
  font-size: 18px;
}
@media only screen and (max-width: 767px) {
  .sec-flow__item-txt {
    font-size: 16px;
  }
}

/*--------------------------------------------------------------------------
   #sec-faq
---------------------------------------------------------------------------*/
.sec-faq {
  position: relative;
  z-index: 0;
  padding: 100px 0 80px;
}
@media only screen and (max-width: 767px) {
  .sec-faq {
    padding: 50px 0;
  }
}
.sec-faq__hdg {
  font-size: 32px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-faq__hdg {
    font-size: 22px;
  }
}
.sec-faq__lead {
  display: flex;
  justify-content: center;
  max-width: 510px;
  margin: 30px auto 0;
}
@media only screen and (max-width: 767px) {
  .sec-faq__lead {
    max-width: none;
    padding: 0 15px;
  }
}
.sec-faq__lead span {
  font-size: 18px;
}
.sec-faq__content {
  max-width: 794px;
  margin: 50px auto 0;
}
@media only screen and (max-width: 767px) {
  .sec-faq__content {
    margin: 30px -20px 0;
  }
}
.sec-faq__item {
  background: rgba(255, 246, 156, 0.5);
}
.sec-faq__item:nth-child(even) {
  background: rgba(177, 227, 233, 0.5);
}
.sec-faq__item-header {
  position: relative;
  padding: 45px 130px 0 110px;
  /* SP open */
}
@media only screen and (max-width: 767px) {
  .sec-faq__item-header {
    padding: 20px 25px 20px 65px;
    cursor: pointer;
  }
  .sec-faq__item-header::before {
    position: absolute;
    top: 28px;
    right: 20px;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    background: #111;
  }
  .sec-faq__item-header::after {
    position: absolute;
    top: 39px;
    right: 29px;
    transform: rotate(180deg);
    width: 11px;
    height: 6px;
    background: url("../../img/index/qa_ico_arrow.svg") no-repeat 50% 50%/cover;
    transition: 0.3s;
  }
}
.sec-faq__item-header .num {
  position: absolute;
  top: 28px;
  left: 45px;
  font-family: "Anton", sans-serif;
  font-size: 37px;
}
@media only screen and (max-width: 767px) {
  .sec-faq__item-header .num {
    top: 22px;
    left: 15px;
    font-size: 29px;
  }
}
.sec-faq__item-header .txt {
  display: flex;
  align-items: center;
  font-size: 17px;
}
@media only screen and (max-width: 767px) {
  .sec-faq__item-header .txt {
    min-height: 48px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-faq__item-header.is-open::after {
    transform: rotate(0deg);
  }
}
.sec-faq__item-body {
  height: 0;
  overflow: hidden;
  transition: height 0.3s;
}
.sec-faq__item-inner {
  position: relative;
  padding: 20px 130px 30px 110px;
}
@media only screen and (max-width: 767px) {
  .sec-faq__item-inner {
    padding: 0px 20px 55px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-faq__item-inner--full {
    padding-bottom: 25px;
  }
}
.sec-faq__item-detail {
  padding: 10px 0 30px;
}
@media only screen and (max-width: 767px) {
  .sec-faq__item-detail {
    padding: 10px 0;
  }
}
.sec-faq__item-ocbtn {
  position: absolute;
  right: 38px;
  bottom: 30px;
}
@media only screen and (max-width: 767px) {
  .sec-faq__item-ocbtn {
    right: 50%;
    bottom: 15px;
    transform: translateX(50%);
  }
}
.sec-faq__item-ocbtn button {
  width: 144px;
  padding: 3px 0;
  background: #fff;
  border-radius: 30px;
  font-size: 16px;
  transition: 0.2s;
}
.sec-faq__item-ocbtn button:hover {
  opacity: 0.9;
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}
.sec-faq__item-ocbtn span {
  position: relative;
  display: inline-block;
  padding-right: 15px;
}
.sec-faq__item-ocbtn span::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%) rotate(180deg);
  width: 9px;
  height: 6px;
  background: url("../../img/index/qa_ico_arrow_black.svg") no-repeat 50% 50%/cover;
  transition: 0.3s;
}
.sec-faq__item-ocbtn .close {
  display: none;
}
.sec-faq__item-ocbtn.is-open {
  bottom: 15px;
}
.sec-faq__item-ocbtn.is-open span::after {
  transform: translateY(-50%) rotate(0deg);
}
.sec-faq__item-ocbtn.is-open .open {
  display: none;
}
.sec-faq__item-ocbtn.is-open .close {
  display: inline-block;
}
.sec-faq__item-emtxt {
  font-size: 27px;
  line-height: 1.5;
}
@media only screen and (max-width: 767px) {
  .sec-faq__item-emtxt + .sec-faq__item-txt {
    margin-top: 5px;
  }
}
.sec-faq__item-txt {
  font-size: 16px;
  line-height: 1.5;
}
.sec-faq__item-btn {
  width: 262px;
  margin-top: 20px;
}
.sec-faq__item-btn--oc {
  width: 340px;
}
@media only screen and (max-width: 767px) {
  .sec-faq__item-btn {
    width: 100%;
  }
}
.sec-faq__item-btn.c-btn-request {
  height: 52px;
  font-size: 16px;
}
@media only screen and (max-width: 767px) {
  .sec-faq__item-btn.c-btn-request {
    height: 60px;
  }
}
.sec-faq__item-btn.c-btn-request a {
  border: 1px solid #fff;
  border-radius: 40px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

/*--------------------------------------------------------------------------
   #sec-cash
---------------------------------------------------------------------------*/
.sec-cash {
  position: relative;
  z-index: 0;
  padding-bottom: 60px;
}
@media only screen and (max-width: 767px) {
  .sec-cash {
    padding-bottom: 35px;
  }
}
.sec-cash__hdg {
  font-size: 31px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-cash__hdg {
    font-size: 23px;
  }
}
.sec-cash__txt01 {
  margin-top: 10px;
  font-size: 31px;
  line-height: 1.5;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-cash__txt01 {
    margin-top: 20px;
    font-size: 23px;
  }
}
.sec-cash__txt01 span {
  padding: 0 10px;
  background: linear-gradient(to bottom, #fff 0, #fff 65%, #fff271 65%, #fff271 100%);
}
.sec-cash__txt02 {
  margin-top: 20px;
  font-size: 31px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-cash__txt02 {
    margin: 40px 0 0 39.46vw;
    font-size: 23px;
    text-align: left;
  }
}
.sec-cash__ico {
  position: absolute;
  bottom: 0;
  left: calc(50% - 490px);
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .sec-cash__ico {
    left: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-cash__ico {
    left: 20px;
  }
}
.sec-cash__ico img {
  width: 200px;
}
@media screen and (min-width: 768px) and (max-width: 1000px) {
  .sec-cash__ico img {
    width: 180px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-cash__ico img {
    width: 122px;
  }
}

/*--------------------------------------------------------------------------
   #sec-ask
---------------------------------------------------------------------------*/
.sec-ask {
  position: relative;
  z-index: 0;
  padding: 120px 0 100px;
}
@media only screen and (max-width: 767px) {
  .sec-ask {
    padding: 0 0 35px;
  }
}
.sec-ask__content {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .sec-ask__content {
    display: block;
  }
}
.sec-ask__img {
  width: 50%;
  padding-left: 3%;
}
@media only screen and (max-width: 767px) {
  .sec-ask__img {
    width: auto;
    padding-left: 0;
    margin: 0 -20px;
  }
}
.sec-ask__img .img {
  width: 87%;
}
@media only screen and (max-width: 767px) {
  .sec-ask__img .img {
    width: 100%;
  }
}
.sec-ask__img .img img {
  width: 100%;
}
.sec-ask__body {
  width: 50%;
}
@media only screen and (max-width: 767px) {
  .sec-ask__body {
    width: auto;
    padding: 30px 5px 0;
  }
}
.sec-ask__hdg {
  font-size: 30px;
  line-height: 1.5;
}
.sec-ask__list {
  margin-top: 20px;
  font-size: 17px;
}
@media only screen and (max-width: 767px) {
  .sec-ask__list {
    line-height: 1.8;
  }
}
.sec-ask__txt {
  margin-top: 20px;
  font-size: 26px;
  line-height: 1.5;
}
@media only screen and (max-width: 767px) {
  .sec-ask__txt {
    font-size: 19px;
  }
}
.sec-ask__caption {
  font-size: 19px;
}
@media only screen and (max-width: 767px) {
  .sec-ask__caption {
    margin-top: 5px;
    font-size: 18px;
  }
}

/*--------------------------------------------------------------------------
   #sec-shop
---------------------------------------------------------------------------*/
.sec-shop {
  position: relative;
  z-index: 0;
  padding: 100px 0 70px;
  background-color: #f5f1e5;
}
@media only screen and (max-width: 767px) {
  .sec-shop {
    padding: 50px 0;
  }
}
.sec-shop__hdg {
  margin-bottom: 50px;
  font-size: 32px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-shop__hdg {
    margin-bottom: 40px;
    font-size: 21px;
  }
}
.sec-shop__wrap {
  padding: 0 20px;
}
@media only screen and (max-width: 767px) {
  .sec-shop__wrap {
    padding: 0;
  }
}
.sec-shop__wrap:not(:first-of-type) {
  margin-top: 60px;
  padding-top: 60px;
  border-top: 1px solid #111;
}
.sec-shop__fuki {
  position: relative;
  display: inline-block;
  max-width: 815px;
  width: 100%;
  margin-bottom: 50px;
  padding: 10px 30px 10px 50px;
  background: #fff69c;
  border: 1px solid #111;
  border-radius: 60px;
  font-size: 22px;
}
@media only screen and (max-width: 767px) {
  .sec-shop__fuki {
    padding: 15px 20px;
    border-width: 1.5px;
    border-radius: 10px;
    font-size: 18px;
    line-height: 1.6;
  }
}
.sec-shop__fuki::before {
  content: "";
  position: absolute;
  bottom: -21px;
  left: 155px;
  width: 40px;
  height: 22px;
  background: url("../../img/index/shop_arrow_fuki.png") no-repeat 50% 50%/contain;
}
@media only screen and (max-width: 767px) {
  .sec-shop__fuki::before {
    left: 50%;
    transform: translateX(-50%);
  }
}
.sec-shop__content {
  display: flex;
}
@media only screen and (max-width: 767px) {
  .sec-shop__content {
    display: block;
  }
}
.sec-shop__body {
  width: 32%;
}
@media only screen and (max-width: 767px) {
  .sec-shop__body {
    width: 76%;
    margin: 0 auto;
  }
}
.sec-shop__body-inner {
  max-width: 300px;
  margin: 0 auto;
}
.sec-shop__map {
  width: 64%;
  margin-left: auto;
}
@media only screen and (max-width: 767px) {
  .sec-shop__map {
    width: auto;
    margin: 25px -20px 0;
  }
}
.sec-shop__map iframe {
  width: 100%;
  aspect-ratio: 622/456;
}
@media only screen and (max-width: 767px) {
  .sec-shop__map iframe {
    aspect-ratio: 16/9;
  }
}
.sec-shop__img {
  max-width: 275px;
}
.sec-shop__img img {
  width: 100%;
}
.sec-shop__name {
  margin-top: 10px;
  font-size: 25px;
}
@media screen and (min-width: 768px) and (max-width: 1020px) {
  .sec-shop__name {
    font-size: 2.45vw;
  }
}
@media only screen and (max-width: 767px) {
  .sec-shop__name {
    font-size: 21px;
  }
}
.sec-shop__info {
  font-size: 17px;
  line-height: 1.5;
}
@media screen and (min-width: 768px) and (max-width: 1020px) {
  .sec-shop__info {
    font-size: 1.66vw;
  }
}
@media only screen and (max-width: 767px) {
  .sec-shop__info {
    font-size: 16px;
  }
}
.sec-shop__tel {
  margin-top: 10px;
  padding: 3px 5px 6px 10px;
  background: #fff;
  border: 1px solid #111;
  border-radius: 5px;
}
@media only screen and (max-width: 767px) {
  .sec-shop__tel {
    margin: 10px -10% 0;
    padding: 9px 10px;
  }
}
.sec-shop__tel a {
  pointer-events: none;
}
@media only screen and (max-width: 767px) {
  .sec-shop__tel a {
    pointer-events: all;
  }
}
.sec-shop__tel-txt {
  font-size: 16px;
}
@media only screen and (max-width: 767px) {
  .sec-shop__tel-txt {
    font-size: 15px;
  }
}
.sec-shop__tel-number {
  display: flex;
  align-items: center;
}
.sec-shop__tel-number .ico {
  line-height: 1;
}
@media screen and (min-width: 768px) and (max-width: 1020px) {
  .sec-shop__tel-number .ico {
    width: 3.72vw;
  }
}
.sec-shop__tel-number .ico img {
  max-width: 100%;
}
@media screen and (min-width: 768px) and (max-width: 1020px) {
  .sec-shop__tel-number .ico img {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .sec-shop__tel-number .ico img {
    width: 41px;
  }
}
.sec-shop__tel-number .txt {
  margin-left: 5px;
  font-size: 33px;
  line-height: 1;
}
@media screen and (min-width: 768px) and (max-width: 1020px) {
  .sec-shop__tel-number .txt {
    font-size: 3.23vw;
  }
}
.sec-shop__sub {
  display: flex;
  margin-top: 50px;
}
@media only screen and (max-width: 767px) {
  .sec-shop__sub {
    display: block;
  }
}
.sec-shop__office {
  max-width: 422px;
  width: 43.9%;
}
@media screen and (min-width: 768px) and (max-width: 1020px) {
  .sec-shop__office {
    width: 48%;
  }
}
@media only screen and (max-width: 767px) {
  .sec-shop__office {
    max-width: none;
    width: 100%;
    margin-top: 50px;
  }
}
.sec-shop__office + .sec-shop__office {
  margin-left: auto;
}
@media only screen and (max-width: 767px) {
  .sec-shop__office + .sec-shop__office {
    margin: 40px 0 0;
  }
}
.sec-shop__office-info {
  display: flex;
  margin-bottom: 15px;
}
@media only screen and (max-width: 767px) {
  .sec-shop__office-info {
    display: block;
  }
}
.sec-shop__office-name {
  font-size: 19px;
  line-height: 1.5;
}
@media screen and (min-width: 768px) and (max-width: 940px) {
  .sec-shop__office-name {
    font-size: 2vw;
  }
}
.sec-shop__office-add {
  margin-left: 60px;
  font-size: 13px;
}
@media screen and (min-width: 768px) and (max-width: 940px) {
  .sec-shop__office-add {
    margin-left: 10px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-shop__office-add {
    margin: 10px 0 0;
    font-size: 17px;
  }
}
@media only screen and (max-width: 767px) {
  .sec-shop__office-map {
    margin: 0 -20px;
  }
}
.sec-shop__office-map iframe {
  width: 100%;
  aspect-ratio: 422/208;
}
@media only screen and (max-width: 767px) {
  .sec-shop__office-map iframe {
    aspect-ratio: 16/9;
  }
}
.sec-shop__area {
  margin-top: 40px;
}
.sec-shop__area-lead {
  font-size: 22px;
  line-height: 1.5;
}
@media only screen and (max-width: 767px) {
  .sec-shop__area-lead {
    font-size: 18px;
  }
}
.sec-shop__area-lead span {
  display: block;
}
@media only screen and (max-width: 767px) {
  .sec-shop__area-lead span {
    font-size: 22px;
    text-align: center;
  }
}
.sec-shop__area-txt {
  font-size: 17px;
  line-height: 1.5;
}
@media only screen and (max-width: 767px) {
  .sec-shop__area-txt {
    font-size: 16px;
  }
}
.sec-shop__area-txt:not(:first-of-type) {
  margin-top: 1em;
}

/*--------------------------------------------------------------------------
   #sec-inquiry
---------------------------------------------------------------------------*/
.sec-inquiry {
  position: relative;
  z-index: 0;
}
.sec-inquiry__contact {
  background: #fff69c;
  padding: 85px 0 90px;
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__contact {
    padding: 45px 0px 60px;
  }
}
.sec-inquiry__contact-hdg {
  font-size: 31px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__contact-hdg {
    font-size: 23px;
  }
}
.sec-inquiry__contact-comment {
  margin-top: 40px;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__contact-comment {
    margin-top: 20px;
  }
}
.sec-inquiry__contact-txt {
  font-size: 30px;
  line-height: 1.5;
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__contact-txt {
    font-size: 23px;
  }
}
.sec-inquiry__contact-txt:not(:first-of-type) {
  margin-top: 5px;
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__contact-txt:not(:first-of-type) {
    margin-top: 0;
  }
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__contact-txt:nth-child(3) {
    margin-top: 15px;
  }
}
.sec-inquiry__contact-txt span {
  padding: 0 10px;
  background: linear-gradient(to bottom, transparent 0, transparent 75%, #fff 75%, #fff 100%);
}
.sec-inquiry__contact-btn {
  width: 473px;
  margin: 40px auto 0;
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__contact-btn {
    width: auto;
    margin-top: 30px;
  }
}
.sec-inquiry__contact-btn.c-btn-request {
  font-size: 19px;
}
.sec-inquiry__contact-btn.c-btn-request a {
  border: 1px solid #fff;
  border-radius: 40px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__icons {
    display: flex;
    align-items: end;
    justify-content: center;
  }
}
.sec-inquiry__ico {
  position: absolute;
  top: 90px;
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__ico {
    position: relative;
    top: auto;
    transform: scale(0.8);
  }
}
.sec-inquiry__ico--left {
  right: calc(50% + 250px);
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__ico--left {
    right: auto;
  }
}
.sec-inquiry__ico--right {
  left: calc(50% + 250px);
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__ico--right {
    left: auto;
  }
}
.sec-inquiry__tel {
  margin-top: 80px;
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__tel {
    margin-top: 50px;
  }
}
.sec-inquiry__tel-hdg {
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__tel-hdg span.mi-sp {
    display: inline-block;
  }
}
.sec-inquiry__tel-hdg span.txt {
  position: relative;
  display: inline-block;
  font-size: 28px;
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__tel-hdg span.txt {
    font-size: 20px;
    padding-top: 50px;
  }
}
.sec-inquiry__tel-hdg span.txt::before {
  content: "";
  position: absolute;
  top: 5px;
  left: -50px;
  width: 39px;
  height: 39px;
  background: url("../../img/common/ico_tel.svg") no-repeat 50% 50%/cover;
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__tel-hdg span.txt::before {
    top: 0px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.sec-inquiry__tel-list {
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  padding: 0 30px;
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__tel-list {
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding: 0;
  }
}
.sec-inquiry__tel-item {
  max-width: 416px;
  width: 45%;
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__tel-item {
    max-width: none;
    width: 100%;
  }
  .sec-inquiry__tel-item:not(:first-of-type) {
    margin-top: 60px;
  }
}
.sec-inquiry__tel-item .hdg {
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px 20px;
  background: #b1e3e9;
  border-radius: 10px;
  font-size: 22px;
  line-height: 1.5;
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__tel-item .hdg {
    font-size: 17px;
  }
}
.sec-inquiry__tel-item .hdg::after {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 20px 18px 0 18px;
  border-color: #b1e3e9 transparent transparent transparent;
}
.sec-inquiry__tel-item .number {
  margin-top: 30px;
  padding: 3px 5px 10px 15px;
  background: #fff;
  border: 1px solid #111;
  border-radius: 6px;
}
@media screen and (min-width: 768px) and (max-width: 940px) {
  .sec-inquiry__tel-item .number {
    padding: 3px 0.53vw 10px 1.59vw;
  }
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__tel-item .number {
    padding: 9px 10px;
  }
}
.sec-inquiry__tel-item .number a {
  pointer-events: none;
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__tel-item .number a {
    pointer-events: all;
  }
}
.sec-inquiry__tel-item .number__name {
  font-size: 25px;
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__tel-item .number__name {
    font-size: 20px;
  }
}
.sec-inquiry__tel-item .number__body {
  display: flex;
  align-items: center;
}
.sec-inquiry__tel-item .number__ico {
  width: 51px;
  line-height: 1;
}
@media screen and (min-width: 768px) and (max-width: 940px) {
  .sec-inquiry__tel-item .number__ico {
    width: 5.42vw;
  }
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__tel-item .number__ico {
    width: 41px;
  }
}
.sec-inquiry__tel-item .number__ico img {
  width: 100%;
}
.sec-inquiry__tel-item .number__txt {
  margin-left: 5px;
  font-size: 44px;
  line-height: 1;
}
@media screen and (min-width: 768px) and (max-width: 940px) {
  .sec-inquiry__tel-item .number__txt {
    font-size: 4.68vw;
  }
}
@media only screen and (max-width: 767px) {
  .sec-inquiry__tel-item .number__txt {
    font-size: 35px;
  }
}

/*--------------------------------------------------------------------------
   custom animation
---------------------------------------------------------------------------*/
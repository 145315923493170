/*--------------------------------------------------------------------------
   #sec-type
---------------------------------------------------------------------------*/
.sec-type {
  position: relative;
  z-index: 0;
  padding-bottom: 105px;
  background-color: #f5f1e5;

	@include mq-sp {
    padding-bottom: 30px;
	}

  &__content {
    position: relative;
    max-width: 990px;
    margin: 0 auto;
    padding: 60px 84px 80px;
    background: #fff;
    border-radius: 10px;

    @media screen and (min-width:768px) and (max-width:1020px) {
      padding: 5.88vw 8.23vw 80px;
    }

    @include mq-sp {
      padding: 30px 15px 0;
    }
  }

  &__hdg {
    font-size: 30px;
    text-align: center;

    @include mq-sp {
      font-size: 21px;
    }
  }

  &__way {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    margin: 50px 0 100px;

    @include mq-sp {
      flex-direction: column;
      margin: 30px 0 40px;
    }

    &-item {
      width: 368px;

      @include mq-sp {
        width: auto;
      }

      &:nth-child(2) {
        margin-left: 10px;

        @include mq-sp {
          margin: 30px 0 0;
        }
      }

      img {
        width: 100%;
      }
    }
  }

  &__txt01 {
    font-size: 31px;
    text-align: center;
    line-height: 1.5;

    @include mq-sp {
      font-size: 25px;
    }
  }

  &__txt02 {
    position: relative;
    font-size: 33px;
    text-align: center;
    line-height: 1.5;
    z-index: 0;

    @include mq-sp {
      font-size: 26px;
    }

    .em {
      color: $request-color;
    }

    &--cross {
      margin: 40px 0 30px;

      @include mq-sp {
        margin: 30px 0;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 132px;
        height: 132px;
        background: url('../../img/index/type_ico_cross.svg') no-repeat 50% 50% / cover;
        z-index: -1;

        @include mq-sp {
          width: 96px;
          height: 96px;
        }
      }
    }

    &--circle {
      margin: 70px 0 50px;

      @include mq-sp {
        margin: 40px 0 30px;
      }

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 156px;
        height: 156px;
        background: url('../../img/index/type_ico_circle.svg') no-repeat 50% 50% / cover;
        z-index: -1;

        @include mq-sp {
          width: 115px;
          height: 115px;
        }
      }
    }
  }

  &__icons {
    @include mq-sp {
      display: flex;
      align-items: end;
      margin-top: 40px;
    }
  }

  &__ico {
    position: absolute;
    bottom: -80px;

    @include mq-sp {
      position: relative;
      bottom: auto;
    }

    img {
      max-width: 100%;

      @include mq-sp {
        //width: 100%;
      }
    }

    &--left {
      left: -22px;

			@media screen and (min-width:768px) and (max-width:900px) {
				left: -60px;
			}
      @include mq-sp {
        left: auto;
        width: 155px;
      }
    }

    &--right {
      right: -19px;

			@media screen and (min-width:768px) and (max-width:900px) {
				right: -45px;
			}
      @include mq-sp {
        right: auto;
        width: 125px;
        margin: 0 10px 0 auto;
      }
    }
  }
}

/*--------------------------------------------------------------------------
   #sec-area
---------------------------------------------------------------------------*/
.sec-area {
  position: relative;
  z-index: 0;
  padding: 100px 0;

	@include mq-sp {
    padding: 50px 0 80px;
	}

  &__hdg {
    font-size: 32px;
    text-align: center;

    @include mq-sp {
      font-size: 21px;
    }
  }

  &__lead {
    margin-top: 15px;
    font-size: 24px;
    text-align: center;

    @include mq-sp {
      font-size: 16px;
    }
  }

  &__txt {
    margin-top: 15px;
    font-size: 24px;
    text-align: center;

    @include mq-sp {
      margin-top: 10px;
      font-size: 16px;
    }

    span {
      background: linear-gradient(to bottom, #fff 0, #fff 70%, #fff271 70%, #fff271 100%);
    }
  }

  &__content {
    margin-top: 50px;
    padding: 50px 70px;
    background: #f5f1e5;
    border-radius: 30px;

    @media screen and (min-width:768px) and (max-width:1020px) {
      padding: 40px;
    }
    @include mq-sp {
      margin-top: 30px;
      padding: 0px 20px;
      border-radius: 6px;
    }

    &--mansion {
      .sec-area__header-ico {
        @include mq-sp {
          img {
            width: 32px;
          }
        }
      }
      .sec-area__header-hdg {
        @include mq-sp {
          margin-left: -32px;
        }
      }
    }

    &--house {
      .sec-area__header-ico {
        @include mq-sp {
          img {
            width: 41px;
          }
        }
      }
      .sec-area__header-hdg {
        @include mq-sp {
          margin-left: -41px;
        }
      }
    }

    &--land {
      display: flex;

      @include mq-sp {
        display: block;
      }

      .sec-area__header {
        width: 16%;

        @media screen and (min-width:768px) and (max-width:1020px) {
          width: 12%;
        }
        @include mq-sp {
          width: auto;
        }
      }
      .sec-area__header-ico {
        @include mq-sp {
          img {
            width: 44px;
          }
        }
      }
      .sec-area__header-hdg {
        @include mq-sp {
          margin-left: -44px;
        }
      }
      .sec-area__body-wrap {
        width: 84%;
        margin: 0;

        @media screen and (min-width:768px) and (max-width:1020px) {
          width: 88%;
        }
        @include mq-sp {
          width: auto;
        }
      }
    }
  }

  &__header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include mq-sp {
      flex-direction: row;
      justify-content: flex-start;
      padding: 20px 0;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        right: 0px;
        transform: translateY(-50%);
        width: 28px;
        height: 28px;
        border-radius: 50%;
        background: #111;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: 9px;
        transform: translateY(-50%) rotate(180deg);
        width: 11px;
        height: 6px;
        background: url('../../img/index/qa_ico_arrow.svg') no-repeat 50% 50% / cover;
        transition: .3s;
      }
    }

    &-ico {
      @include mq-sp {
      }
    }

    &-hdg {
      margin-top: 20px;
      font-size: 27px;

      @include mq-sp {
        margin-top: 0;
        font-size: 18px;
        width: 100%;
        text-align: center;

        .mi-sp {
          display: inline-block;
        }
      }
    }

    /* SP open */
    @include mq-sp {
      &.is-open {
        &::after {
          transform: translateY(-50%) rotate(0deg);
        }
      }
    }
  }

  &__body {
    @include mq-sp {
      padding: 5px 0 25px;
    }

    &-wrap {
      margin-top: 40px;

      @include mq-sp {
        margin-top: 0;
        height: 0;
        overflow: hidden;
        transition: height .3s;
      }
    }

    &-hdg {
      margin: 50px 0 30px;
      font-size: 21px;
      text-align: center;

      @include mq-sp {
        margin: 30px 0 15px;
        font-size: 24px;
      }
    }

    &-txt {
      font-size: 17px;
      line-height: 1.6;

      @include mq-sp {
        font-size: 16px;
      }

      & + .sec-area__land-hdg {
        margin-top: 1.2em;
      }
    }
  }

  &__land-hdg {
    font-size: 17px;

    @include mq-sp {
      font-size: 16px;
    }
  }

  &__land-hdg {
    font-size: 17px;

    @include mq-sp {
      font-size: 16px;
    }
  }

  &__inquiry {
    display: flex;
    padding-left: 10%;

    @media screen and (min-width:768px) and (max-width:1020px) {
      padding-left: 5%;
    }
    @include mq-sp {
      display: block;
      padding-left: 0;
    }

    &-item {
      width: 50%;

      @include mq-sp {
        width: auto;
      }

      &--tel {
        margin-left: 5%;

        @include mq-sp {
          margin: 20px 0 0;
        }

        .sec-area__inquiry-txt {
          padding-left: 10px;
        }
      }
    }

    &-txt {
      display: flex;
      align-items: end;
      font-size: 17px;
      line-height: 1.5;

      @include mq-sp {
      }
    }

    &-btn {
      max-width: 300px;
      width: 100%;
      margin-top: 10px;

      &.c-btn-request {
        height: 46px;
        font-size: 17px;

        @include mq-sp {
          height: 70px;
        }

        a {
          border: 1px solid #fff;
          border-radius: 40px;
          box-shadow: 2px 2px 8px rgba(#000, .2);
        }
      }

      &.c-btn-tel {
        height: 46px;
        font-size: 17px;

        @include mq-sp {
          height: 70px;
        }

        a {
          border: 1px solid #fff;
          border-radius: 40px;
          box-shadow: 2px 2px 8px rgba(#000, .2);
        }
      }
    }
  }

  &__map {
    margin-top: 80px;

    @include mq-sp {
      margin-top: 50px;
    }

    &-hdg {
      font-size: 23px;
      text-align: center;
    }

    &-lead {
      margin-top: 10px;
      font-size: 17px;
      text-align: center;
    }

    &-img {
      margin-top: 50px;
      text-align: center;

      svg {
        max-width: 100%;

        path {
          pointer-events: none;
        }

        a path {
          pointer-events: all;
          transition: fill .2s, stroke 0s;
        }

        a:hover {
          path {
            //opacity: .7;
          }
          #path-kyoto path {
            //fill: #fff271;
            fill: #E7E7E7;
          }
          #path-hyogo path {
            //fill: #dce62f;
            fill: #E7E7E7;
            stroke: #E7E7E7 !important;
          }
          #path-osaka path {
            //fill: #b1e3e9;
            fill: #E7E7E7;
          }
          #path-nara path {
            //fill: #e8cca5;
            fill: #E7E7E7;
            transition: fill .2s, stroke 0s;
          }
          #path-wakayama path {
            //fill: #ffe3cd;
            fill: #E7E7E7;
          }
        }
      }
    }
  }

  &__slide {
    .swiper-slide__img img {
      width: 100%;
      height: auto;
    }

    .swiper-button-prev {
      margin-left: -10px;
    }
    .swiper-button-next {
      margin-right: -10px;

    }

    .swiper[data-id="1"] {
      .swiper-button-prev,
      .swiper-button-next {
        top: calc(50% + 7vw);
      }
    }
  }
}

/*--------------------------------------------------------------------------
   #sec-area-popup
---------------------------------------------------------------------------*/
.sec-area-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 99;
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all .6s ease-in-out;

  @include mq-sp {
    display: none;
  }

	.sec-area-popup__overlay {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100vh;
		background: rgba(255,255,255,0.85);
	}

	&__body {
		position: fixed;
		top: calc(50% + 50px);
		left: 50%;
		transform: translate(-50%, -50%);
		width: 578px;
		padding-top: 35px;
		background-color: #fff;
		border: 1px solid #d0cfcf;
    pointer-events: none;
    visibility: hidden;
    opacity: 0;
    transition: all .6s ease-in-out;

    .swiper {
      overflow: hidden;
    }

    .swiper-slide__img img {
      width: 100%;
      height: auto;
    }
	}

	&__close {
		position: absolute;
		top: -20px;
		right: -20px;
		z-index: 2;

		button {
      position: relative;
			display: block;
      width: 40px;
      height: 40px;
      border-radius: 50%;
			transition: .3s;
      background-color: #000;

      &::before,
      &::after {
        display: inline-block;
        position: absolute;
        left: 0;
        width: 24px;
        height: 2px;
        transition: all 0.4s;
        box-sizing: border-box;
        background-color: #fff;
        content: '';
        transform: translate(8px, 0px) rotate(-45deg);
      }
      &::after {
        transform: translate(8px, 0px) rotate(45deg);
      }

			&:hover {
				transform: scale(1.2);
			}
		}
	}
}


// show
.sec-area-popup.is-show {
  pointer-events: all;
  visibility: visible;
  opacity: 1;
  transition: all .3s ease-in-out;

  .sec-area-popup__body {
    pointer-events: all;
    visibility: visible;
    opacity: 1;
    transition: all .6s ease-in-out;
    transition-delay: .3s;
  }
}

/*--------------------------------------------------------------------------
   #sec-ask
---------------------------------------------------------------------------*/
.sec-ask {
  position: relative;
  z-index: 0;
  padding: 120px 0 100px;

	@include mq-sp {
    padding: 0 0 35px;
	}

  &__content {
    display: flex;
    align-items: center;

    @include mq-sp {
      display: block;
    }
  }

  &__img {
    width: 50%;
    padding-left: 3%;

    @include mq-sp {
      width: auto;
      padding-left: 0;
      margin: 0 -20px;
    }

    .img {
      width: 87%;

      @include mq-sp {
        width: 100%;
      }

      img {
        width: 100%;
      }
    }
  }

  &__body {
    width: 50%;

    @include mq-sp {
      width: auto;
      padding: 30px 5px 0;
    }
  }

  &__hdg {
    font-size: 30px;
    line-height: 1.5;

    @include mq-sp {
    }
  }

  &__list {
    margin-top: 20px;
    font-size: 17px;

    @include mq-sp {
      line-height: 1.8;
    }
  }

  &__txt {
    margin-top: 20px;
    font-size: 26px;
    line-height: 1.5;

    @include mq-sp {
      font-size: 19px;
    }
  }

  &__caption {
    font-size: 19px;

    @include mq-sp {
      margin-top: 5px;
      font-size: 18px;
    }
  }
}

/*--------------------------------------------------------------------------
   #sec-worry
---------------------------------------------------------------------------*/
.sec-worry {
  position: relative;
  z-index: 0;
  padding: 100px 0;
  background-color: #f5f1e5;

	@include mq-sp {
    padding: 50px 0;
	}

  &__header {
    position: relative;
    text-align: center;
  }

  &__icons {
    @include mq-sp {
      display: flex;
      align-items: end;
      justify-content: center;
    }
  }

  &__ico {
    position: absolute;
    top: 20px;

    @include mq-sp {
      position: relative;
      top: auto;
      transform: scale(.8);
    }

    &--left {
      right: calc(50% + 250px);
      @include mq-sp {
        right: auto;
      }
    }
    &--right {
      left: calc(50% + 250px);
      @include mq-sp {
        left: 16px;
      }
    }
  }

  &__hdg {
    font-size: 32px;

    @include mq-sp {
      margin-top: 10px;
      font-size: 22px;
    }

    span {
      display: block;
    }

    .txt02 {
      color: $em-color;
    }
  }

  &__slide {
    margin-top: 50px;

    @include mq-sp {
      margin-top: 30px;
    }

    &.m-slide {
      @include mq-sp {
        max-width: none;
      }
    }

    .swiper-slide {
      height: auto;
    }

    .swiper-slide__img {
      position: relative;
      height: 100%;

      img {
        width: 100%;
        height: auto;
      }
    }

    .swiper-slide__btn {
      position: absolute;
      left: 50%;
      bottom: 68px;
      width: 74%;
      transform: translate(-50%, 0);
      margin-top: 10px;
      font-weight: 700;

      &.c-btn-request {
        height: 46px;
        font-size: 17px;

        @media screen and (min-width:768px) and (max-width:1024px) {
          bottom: 8%;
          font-size: 1.7vw;
        }

        @include mq-sp {
          bottom: 8%;
          height: 12vw;
          font-size: 3.4vw;
        }

        a {
          border: 1px solid #fff;
          border-radius: 1000px;
          box-shadow: 2px 2px 8px rgba(#000, .2);
        }
      }
    }
  }

  &__txt {
    margin-top: 50px;
    font-size: 24px;
    text-align: center;

    @include mq-sp {
      margin-top: 5px;
      font-size: 18px;
    }
  }

  &__btn {
    width: 432px;
    height: 78px;
    margin: 30px auto 0;
    font-size: 23px;

    @include mq-sp {
      width: 90%;
      height: 50px;
      margin-top: 10px;
      font-size: 16px;
    }

    &.c-btn-request {
      a {
        border-radius: 40px;
        box-shadow: 7px 7px 0 rgba(#000, .2);
        @include mq-sp {
          box-shadow: 5px 5px 0 rgba(#000, .2);
        }
      }
    }
  }
}

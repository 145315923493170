/*--------------------------------------------------------------------------
   #sec-which
---------------------------------------------------------------------------*/
.sec-which {
  position: relative;
  z-index: 0;
  padding: 100px 0 70px;
  background-color: #f5f1e5;

	@include mq-sp {
    padding: 50px 0 30px;
	}

  &__header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mq-sp {
      flex-direction: column;
    }
  }

  &__header-ico {
    margin-left: -70px;

    @include mq-sp {
      margin-left: 0;
    }

    img {
      max-width: 100%;

      @include mq-sp {
        width: 82px;
      }
    }
  }

  &__hdg {
    margin-left: 30px;
    font-size: 32px;

    @include mq-sp {
      margin: 10px 0 20px;
      font-size: 23px;
      line-height: 1.3;
      text-align: center;
    }
  }

  &__content {
    max-width: 770px;
    margin: 0 auto 75px;
    padding: 30px 95px 40px;
    background: #fff;
    border-radius: 10px;

    @include mq-sp {
      margin: 0 auto 20px;
      padding: 30px 15px;
    }

    &--kaitori {
      .sec-which__content-img {
        @include mq-sp {
          width: 90%;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
  }

  &__content-hdg {
    font-size: 26px;
    text-align: center;

    @include mq-sp {
      font-size: 21px;
    }
  }

  &__content-txt {
    margin-top: 20px;
    font-size: 20px;

    @include mq-sp {
      margin-top: 15px;
      font-size: 16px;
    }

    span {
      background: linear-gradient(to bottom, #fff 0, #fff 70%, #fff271 70%, #fff271 100%);
    }
  }

  &__content-img {
    margin-top: 25px;
    text-align: center;

    @include mq-sp {
      margin-top: 20px;
    }

    img {
      max-width: 100%;

      @include mq-sp {
        width: 100%;
      }
    }
  }
}

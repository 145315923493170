/*--------------------------------------------------------------------------
   #sec-flow
---------------------------------------------------------------------------*/
.sec-flow {
  position: relative;
  z-index: 0;
  padding: 130px 0 140px;
  background-color: $key-color;

	@include mq-sp {
    padding: 50px 0 55px;
	}

  &__hdg {
    font-size: 32px;
    text-align: center;

    @include mq-sp {
      font-size: 21px;
    }
  }

  &__slide {
    margin-top: 65px;

    @include mq-sp {
      margin-top: 30px;
    }

    .swiper-slide {
      width: 434px !important;
      background-color: #fff;

      @media screen and (min-width:768px) and (max-width:1080px) {
        width: 40vw !important;
      }
      @include mq-sp {
        width: 100% !important;
      }
    }

    &.m-slide {
      @include mq-sp {
        max-width: calc(100vw - 40px);

        .swiper-button-prev {
          margin-left: -28px;
        }
        .swiper-button-next {
          margin-right: -28px;
        }
      }
    }
  }

  &__item {
    padding: 50px;
    background-color: #fff;

    @media screen and (min-width:768px) and (max-width:1080px) {
      padding: 4.62vw;
    }
    @include mq-sp {
      min-height: 395px;
      padding: 30px 20px;
    }

    &-header {
      display: flex;
      align-items: center;
    }

    &-number {
      font-family: $font-family-anton;
      font-size: 46px;
      font-weight: 400;
      line-height: 1;
      letter-spacing: .05em;

      @media screen and (min-width:768px) and (max-width:1080px) {
        font-size: 4.25vw;
      }
      @include mq-sp {
        font-size: 40px;
      }
    }

    &-hdg {
      margin-left: 20px;
      font-size: 27px;

      @media screen and (min-width:768px) and (max-width:1080px) {
        margin-left: 1.85vw;
        font-size: 2.5vw;
      }
      @include mq-sp {
        font-size: 22px;
      }
    }

    &-img {
      margin-top: 25px;

      img {
        width: 100%;
        height: auto;
      }
    }

    &-txt {
      margin-top: 20px;
      font-size: 18px;

      @include mq-sp {
        font-size: 16px;
      }
    }
  }
}

/*--------------------------------------------------------------------------
   #sec-mv
---------------------------------------------------------------------------*/
.sec-mv {
  position: relative;
  z-index: 0;

	@include mq-sp {
	}

  &__content {
    position: relative;
    min-height: 566px;
    background: #f5f1e5 url('../../img/index/mv_bg.png') no-repeat 50% 100% / contain;

    @include mq-sp {
      padding-bottom: 30px;
      background: #f5f1e5;
    }
  }

  &__slide {
    position: relative;
    max-width: 1350px;
    height: 566px;
    padding: 20px 10px 0;
    margin: auto auto 0;
    z-index: 3;
    pointer-events: none;

    @include mq-sp {
      max-width: none;
      height: auto;
      padding: 0 10px 80px;
      background: url('../../img/index/mv_bg_sp.png') no-repeat 50% 100% / contain;
      pointer-events: all;
    }

    .swiper {
      height: 100%;

      @include mq-sp {
        overflow: visible;
      }
    }

    .swiper-slide {
      min-height: 506px;
      pointer-events: none;

      &__img {
        height: 100%;

        img {
          position: absolute;
          bottom: 0;
          max-width: 100%;

          @media screen and (min-width:951px) and (max-width: 1300px) {
            width: calc(100vw - 406px);
            height: auto;
          }
          @media screen and (min-width:861px) and (max-width: 950px) {
            width: auto;
            height: 100%;
          }
          @media screen and (min-width:768px) and (max-width: 860px) {
            width: calc(100% - 400px);
            height: auto;
          }
          @include mq-sp {
            position: static;
            width: 100%;
            height: auto;
          }
        }
      }
    }

    .swiper-pagination {
      display: none;

      @include mq-sp {
        display: block;
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .swiper-pagination-bullet {
      width: 13px;
      height: 13px;
      margin: 0 5px !important;
      border-radius: 50%;
      border: 1px solid #111;
      background: #fff;
      transition: .3s;
      opacity: 1;

      &:hover {
        background: $key-color;
      }

      &.swiper-pagination-bullet-active {
        background: #111;
      }
    }
  }

  &__request {
    position: absolute;
    top: 60px;
    left: calc(50% + 260px);
    width: 384px;
    height: 456px;
    padding: 60px 30px 0;
    background: url('../../img/index/mv_bg_select.png') no-repeat 50% 50% / contain;
    z-index: 2;

    @media screen and (min-width:768px) and (max-width: 1300px) {
      left: auto;
      right: 10px;
    }
    @include mq-sp {
      position: relative;
      top: auto;
      right: auto;
      left: auto;
      max-width: 339px;
      width: 100%;
      margin: 0 auto;
      z-index: 999;
    }

    .m-request-select__item {
      &:not(:first-of-type) {
        margin-top: 22px;
      }

      .c-select {
        height: 57px;

        @include mq-sp {
          height: 50px;
        }
      }
    }

    .c-btn-request {
      width: 100%;
      height: 56px;
      margin-top: 20px;
    }
  }

  &__request-txt01 {
    position: absolute;
    top: -30px;
    left: -20px;
  }

  &__request-txt02 {
    margin-bottom: 10px;
    font-size: 32px;
    text-align: center;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 20px;

    @include mq-sp {
      display: none;
    }
  }

  &__list-item {
    display: inline-block;
    padding: 5px 20px;
    margin-bottom: 15px;
    background: #f5f1e5;
    border-radius: 18px;
    font-size: 18px;
    color: #111;

    &:not(:first-of-type) {
      margin-left: 16px;
    }
  }
}

/*--------------------------------------------------------------------------
   #sec-cash
---------------------------------------------------------------------------*/
.sec-cash {
  position: relative;
  z-index: 0;
  padding-bottom: 60px;

	@include mq-sp {
    padding-bottom: 35px;
	}

  &__hdg {
    font-size: 31px;
    text-align: center;

    @include mq-sp {
      font-size: 23px;
    }
  }

  &__txt01 {
    margin-top: 10px;
    font-size: 31px;
    line-height: 1.5;
    text-align: center;

    @include mq-sp {
      margin-top: 20px;
      font-size: 23px;
    }

    span {
      padding: 0 10px;
      background: linear-gradient(to bottom, #fff 0, #fff 65%, #fff271 65%, #fff271 100%);
    }
  }

  &__txt02 {
    margin-top: 20px;
    font-size: 31px;
    text-align: center;

    @include mq-sp {
      margin: 40px 0 0 39.46vw;
      font-size: 23px;
      text-align: left;
    }
  }

  &__ico {
    position: absolute;
    bottom: 0;
    left: calc(50% - 490px);

    @media screen and (min-width:768px) and (max-width:1000px) {
      left: 10px;
    }
    @include mq-sp {
      left: 20px;
    }

    img {
      width: 200px;

      @media screen and (min-width:768px) and (max-width:1000px) {
        width: 180px;
      }
      @include mq-sp {
        width: 122px;
      }
    }
  }
}

/*--------------------------------------------------------------------------
   #sec-merit
---------------------------------------------------------------------------*/
.sec-merit {
  position: relative;
  z-index: 0;
  padding: 0 0 70px;
  background-color: #f5f1e5;

	@include mq-sp {
    padding-bottom: 30px;
	}

  &__header {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;

    @include mq-sp {
      flex-direction: column;
    }
  }

  &__header-ico {
    margin-right: -70px;

    @include mq-sp {
      margin-right: 0;
    }

    img {
      max-width: 100%;

      @include mq-sp {
        width: 82px;
      }
    }
  }

  &__hdg {
    margin-right: 30px;
    font-size: 32px;

    @include mq-sp {
      margin: 10px 0 20px;
      font-size: 23px;
      line-height: 1.3;
      text-align: center;
    }
  }

  &__content {
    margin-bottom: 70px;

    @include mq-sp {
      margin-bottom: 40px;
    }

    img {
      @include mq-sp {
      }
    }
  }

  &__content-hdg {
    text-align: center;

    span {
      display: inline-block;
      padding: 0 30px;
      border-radius: 5px;
      line-height: 55px;

      @include mq-sp {
        display: block;
        padding: 5px;
        line-height: normal;
      }
    }

    &--chukai span {
      font-size: 28px;
      background: $contact-color;

      @include mq-sp {
        font-size: 22px;
      }
    }

    &--kaitori span {
      font-size: 24px;
      background: $key-color;

      @include mq-sp {
        font-size: 22px;
      }
    }
  }

  &__slide {
    margin-top: 45px;

    @include mq-sp {
      margin-top: 20px;
    }

    .swiper-slide__img img {
      width: 100%;
      height: auto;
    }
  }

  &__content-body {
    display: flex;
    margin-top: 60px;
    padding: 50px 0;
    background: #fff url("../../img/common/bg_grid_blue.png") repeat 0 0 / 24px 24px;
    box-shadow: 0 0 6px rgba(#000, .3);

    @include mq-sp {
      display: block;
      margin-top: 30px;
      padding: 40px 20px;
      background: #fff url("../../img/common/bg_grid_blue.png") repeat 0 0 / 18px 18px;
    }

    img {
      max-width: 100%;

      @include mq-sp {
        width: 100%;
      }
    }
  }

  &__point {
    width: 50%;
    padding-right: 10px;

    @media screen and (min-width:768px) and (max-width:1000px) {
      padding-right: .1vw;
    }
    @include mq-sp {
      width: auto;
      padding-right: 0;
    }

    &-hdg {
      font-size: 27px;
      margin-bottom: 6px;

			@media screen and (min-width:768px) and (max-width:1000px) {
				font-size: 2.7vw;
			}
      @include mq-sp {
        display: flex;
        font-size: 20px;
      }

      span {
        position: relative;
        top: 2px;
        margin-right: 8px;
        display: inline-block;
        line-height: 1;

        @include mq-sp {
          top: auto;
          margin-right: 5px;
          padding-top: 5px;
        }
      }
    }

    &-item {
      font-size: 20px;

			@media screen and (min-width:768px) and (max-width:1000px) {
				font-size: 2vw;
			}
      @include mq-sp {
        font-size: 17px;
      }

      span {
        display: block;
        font-size: 17px;
        font-weight: 500;
        line-height: 1.6;

        @media screen and (min-width:768px) and (max-width:1000px) {
          font-size: 1.7vw;
        }
      }
    }

    &--merit {
      padding-left: 50px;

			@media screen and (min-width:768px) and (max-width:1000px) {
        padding-left: 20px;
			}
      @include mq-sp {
        padding-left: 0;
      }

      .sec-merit__point-hdg span {
        top: 1px;

        @include mq-sp {
          top: 0;
        }

        img {
          width: 26px;

          @include mq-sp {
            width: 22px;
          }
        }
      }
    }

    &--demerit {
      @include mq-sp {
        margin-top: 40px;
      }

      .sec-merit__point-hdg span {
        img {
          width: 26px;

          @include mq-sp {
            width: 22px;
          }
        }
      }
    }
  }
}

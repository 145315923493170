/*--------------------------------------------------------------------------
   #sec-news
---------------------------------------------------------------------------*/
.sec-news {
  position: relative;
  z-index: 0;
  padding: 90px 0;

	@include mq-sp {
    padding: 40px 0;
	}

  &__content {
    position: relative;
    max-width: 900px;
    margin: 0 auto;
    padding: 55px 60px 60px;
    border: 8px solid #e7e7e7;
    border-radius: 18px;

    @include mq-sp {
      border-width: 4px;
      padding: 20px 20px 30px;
    }
  }

  &__hdg {
    font-size: 32px;
    text-align: center;

    @include mq-sp {
      font-size: 21px;
    }
  }

  &__list {
    margin-top: 10px;

    @include mq-sp {
      margin-top: 0;
    }
  }

  &__item {
    padding: 8px 0;
    font-size: 20px;
    font-weight: 500;
    border-bottom: 1px solid #111;

    @include mq-sp {
      padding: 14px 0;
      font-size: 14px;
    }
  }
}

/*--------------------------------------------------------------------------
   #sec-faq
---------------------------------------------------------------------------*/
.sec-faq {
  position: relative;
  z-index: 0;
  padding: 100px 0 80px;

	@include mq-sp {
    padding: 50px 0;
	}

  &__hdg {
    font-size: 32px;
    text-align: center;

    @include mq-sp {
      font-size: 22px;
    }
  }

  &__lead {
    display: flex;
    justify-content: center;
    max-width: 510px;
    margin: 30px auto 0;

    @include mq-sp {
      max-width: none;
      padding: 0 15px;
    }

    span {
      font-size: 18px;
    }
  }

  &__content {
    max-width: 794px;
    margin: 50px auto 0;

    @include mq-sp {
      margin: 30px -20px 0;
    }
  }

  &__item {
    background: rgba($key-color, .5);

    &:nth-child(even) {
      background: rgba($contact-color, .5);
    }

    &-header {
      position: relative;
      padding: 45px 130px 0 110px;

      @include mq-sp {
        padding: 20px 25px 20px 65px;
        cursor: pointer;

        &::before {
          //content: '';
          position: absolute;
          top: 28px;
          right: 20px;
          width: 28px;
          height: 28px;
          border-radius: 50%;
          background: #111;
        }

        &::after {
          //content: '';
          position: absolute;
          top: 39px;
          right: 29px;
          transform: rotate(180deg);
          width: 11px;
          height: 6px;
          background: url('../../img/index/qa_ico_arrow.svg') no-repeat 50% 50% / cover;
          transition: .3s;
        }
      }

      .num {
        position: absolute;
        top: 28px;
        left: 45px;
        font-family: $font-family-anton;
        font-size: 37px;

        @include mq-sp {
          top: 22px;
          left: 15px;
          font-size: 29px;
        }
      }

      .txt {
        display: flex;
        align-items: center;
        font-size: 17px;

        @include mq-sp {
          min-height: 48px;
          font-size: 16px;
        }
      }

      /* SP open */
      @include mq-sp {
        &.is-open {
          &::after {
            transform: rotate(0deg);
          }
        }
      }
    }

    &-body {
      height: 0;
      overflow: hidden;
      transition: height .3s;
    }

    &-inner {
      position: relative;
      padding: 20px 130px 30px 110px;

      @include mq-sp {
        padding: 0px 20px 55px;
      }

      &--full {
        @include mq-sp {
          padding-bottom: 25px;
        }
      }
    }

    &-detail {
      padding: 10px 0 30px;

      @include mq-sp {
        padding: 10px 0;
      }
    }

    &-ocbtn {
      position: absolute;
      right: 38px;
      bottom: 30px;

      @include mq-sp {
        right: 50%;
        bottom: 15px;
        transform: translateX(50%);
      }

      button {
        width: 144px;
        padding: 3px 0;
        background: #fff;
        border-radius: 30px;
        font-size: 16px;
        transition: .2s;

        &:hover {
          opacity: .9;
          box-shadow: 0 0 3px rgba(#000, .2);
        }
      }

      span {
        position: relative;
        display: inline-block;
        padding-right: 15px;

        &::after {
          content: '';
          position: absolute;
          top: 50%;
          right: 0px;
          transform: translateY(-50%) rotate(180deg);
          width: 9px;
          height: 6px;
          background: url('../../img/index/qa_ico_arrow_black.svg') no-repeat 50% 50% / cover;
          transition: .3s;
        }
      }

      .close {
        //opacity: 0;
        //overflow: hidden;
        display: none;
      }

      &.is-open {
        bottom: 15px;

        span {
          &::after {
            transform: translateY(-50%) rotate(0deg);
          }
        }

        .open {
          display: none;
        }
        .close {
          display: inline-block;
        }
      }
    }

    &-emtxt {
      font-size: 27px;
      line-height: 1.5;

      & + .sec-faq__item-txt {
        @include mq-sp {
          margin-top: 5px;
        }
      }
    }

    &-txt {
      font-size: 16px;
      line-height: 1.5;
    }

    &-btn {
      width: 262px;
      margin-top: 20px;

      &--oc {
        width: 340px;
      }

      @include mq-sp {
        width: 100%;
      }

      &.c-btn-request {
        height: 52px;
        font-size: 16px;

        @include mq-sp {
          height: 60px;
        }

        a {
          border: 1px solid #fff;
          border-radius: 40px;
          box-shadow: 2px 2px 8px rgba(#000, .2);
        }
      }
    }
  }
}

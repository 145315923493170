/*--------------------------------------------------------------------------
   #sec-intro
---------------------------------------------------------------------------*/
.sec-intro {
  position: relative;
  z-index: 0;
  padding: 50px 0 70px;

	@include mq-sp {

	}

  &__quocard {
    position: relative;
    max-width: 924px;
    margin: 20px auto 0;
    padding: 24px 25px 15px 23px;
    border: 2px solid #111;
    border-radius: 10px;

    @media only screen and (max-width: 1000px) {
      width: 90%;
    }
  }

  &__quocard-hdg {
    margin-right: 70px;

    @include mq-sp {
      margin-right: 0;
    }

    img {
      max-width: 100%;

      @include mq-sp {
       margin-top: 30px;
      }
    }
  }

  &__quocard-obj {
    position: absolute;
    top: -20px;
    right: 10px;

    @include mq-sp {
      top: -35px;
      right: -20px;
    }

    img {
      @include mq-sp {
        width: 94px;
      }
    }
  }

  &__quocard-caption {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 500;
    line-height: 1.3;
  }

  &__quocard-limit {
    margin-top: -85px;
    margin-left: -65px;

    @include mq-sp {
      //margin: -40px 0 10px;
    }
  }

  &__bnrs {
    display: flex;
    align-items: end;
    justify-content: center;
    margin-top: 30px;

    @include mq-sp {
      display: block;
      margin-top: 0;
    }
  }

  &__bnr {
    margin: 0 15px;

    @include mq-sp {
      margin: 15px 0 0;
    }

    img {
      max-width: 100%;

      @include mq-sp {
        width: 100%;
      }
    }
  }
}

/*--------------------------------------------------------------------------
   common
---------------------------------------------------------------------------*/

/* .head-btn
-----------------------------------------------------------------*/

.head-btn {
  .nav {
    display: flex;
    flex-direction: row-reverse;


    @include mq-sp {
      display: block;
    }

    .item {
      width: 100%;

      a {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 20px 0;
        font-size: 25px;
        font-weight: 700;
        transition: opacity .2s;

        &:hover {
          opacity: .5;
        }

        @media screen and (min-width:768px) and (max-width: 1500px) {
          font-size: 1.5vw;
        }

         @include mq-sp {
          padding: 20px;
          justify-content: flex-start;
          font-size: 4.2vw;

          &::before {
            content: '';
            position: absolute;
            top: 50%;
            right: 20px;
            transform: translateY(-50%);
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background: #fff;
          }

          &::after {
            content: '';
            position: absolute;
            top: 50%;
            right: 29px;
            transform: translateY(-50%) rotate(180deg);
            width: 15px;
            height: 8px;
            background: url('../../img/common/ico_arrow_select.svg') no-repeat 50% 50% / cover;
            transition: .3s;
          }
        }
      }

      &--kaitori {
        background-color: #FFF675;

        a::after {
          top: 42%;
          right: 27px;
          rotate: 180deg;
        }
      }

      &--owner {
        position: relative;
        background-color: #3A82CD;

        a {
          color: #fff;
        }

        a::after {
          top: 47%;
          right: 29px;
          rotate: 90deg;
        }


        &::before {
          position: absolute;
          top: 0;
          left: -20px;
          width: 100%;
          height: 100%;
          background-color: #3A82CD;
          transform: skewX(160deg);
          content: '';
        }

      }
    }

  }
}
